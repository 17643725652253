import "../DeleteModal/DeleteModal.scss"
import "./UploadModal.scss"
import {useDispatch, useSelector} from "react-redux";
import {hideUploadModalAction} from "../../store/reducers/folderReducer";
import {useEffect, useState} from "react";
import IconPlus from "../../assets/icons/icon_plus.svg";
import IconDelete from "../../assets/icons/icon_delete.svg";
import SelectGosteh from "../SelectGosteh/SelectGosteh";
import UploadAnnotation from "../MyRoom/Administrator/CreateDataset/UploadAnnotation";
import {
    getSanitizersAction,
    importFileAction,
    uploadFileAction
} from "../../store/actions/FolderAction";
import {disableImportModeAction} from "../../store/reducers/appReducer";

function UploadModal(){

    const dispatch = useDispatch()

    const {currentDataset,sanitizers} = useSelector(store=>store.dataset)
    const {importMode} = useSelector(store=>store.app)

    const accept_formats = currentDataset.info.data_type === "photo" && !importMode ? ["jpg","jpeg","png","bmp","zip","tar","gz"] :
        currentDataset.info.data_type === "time_series" && !importMode ? ["csv","zip","tar","gz"] :
            currentDataset.info.data_type === "text" && !importMode ? ["txt","docx"] : ["zip","tar","gz"]


    const [sanitList,setSanitList] = useState([])
    const [files,setFiles] = useState([])
    const [validFiles,setValidFiles] = useState(true)

    const def_opt = "Не выбрано"

    const [options,setOptions] = useState([])

    useEffect(()=>{

      dispatch(getSanitizersAction(currentDataset._id))

    },[currentDataset])

    useEffect(()=>{
        let opt_list = sanitizers.map(el => (el.name))
        setOptions(opt_list)
    },[sanitizers])

    function handleAddSanit(e){
        if(sanitList.length < sanitizers.length){
            setSanitList([...sanitList,{name:"",id:Date.now().toString()}])
        }
    }

    function handleSanitChange(option,index){
        let new_sanit = sanitList
        new_sanit[index].name = option
        setSanitList(new_sanit)
        let used_names = new_sanit.map(el => el.name)
        let new_opt = sanitizers.map(el => (el.name))
        new_opt = new_opt.filter( ( el ) => !used_names.includes( el ) );
        setOptions(new_opt)
    }

    function handleDeleteSanit(id){
        let name_opt = sanitList.filter(s=>s.id === id)[0].name
        setSanitList(sanitList.filter(s=>s.id !== id))
        let new_opt = options
        if (name_opt){
            new_opt.push(name_opt)
        }
        setOptions(new_opt)
    }

    function handleUpload(){
        let opts = sanitList.map(el => el.name)
        let sanit_list = sanitizers.filter(el => opts.includes(el.name)).map(el => el._id)

        dispatch((hideUploadModalAction()))
        if (importMode){
            dispatch(importFileAction(Array.from(files),currentDataset._id,sanit_list.toString()))
        }
        else {
            dispatch(uploadFileAction(Array.from(files),currentDataset._id,sanit_list.toString()))
        }
        dispatch(disableImportModeAction())
    }

    function handleClose(){
        dispatch(hideUploadModalAction())
        dispatch(disableImportModeAction())
    }

    return (
        <div className="modal">
            <div className="modal_upload">
                <div className="modal_window">
                    <p className="gt_h3">{importMode ? "Импорт данных" : "Добавить файлы"}</p>

                        <div className="sanit_list">
                            {sanitList.map((sanit,i) => (
                                <div className="sanit_item" key={sanit.id}>
                                    <div className="sanit_item_select">
                                        <SelectGosteh options={options} default_option={def_opt} value={sanit.name} selectChange={handleSanitChange} index={i}/>
                                    </div>
                                    <button type="button" className="delete_btn" onClick={()=>handleDeleteSanit(sanit.id)}><img src={IconDelete} alt="delete"/></button>
                                </div>
                            ))}
                            <button type="button" className= "add_satit" onClick={handleAddSanit}>
                                <img src={IconPlus} alt="icon plus"/>
                                <p className="gt_pt gt_gb">Добавить {currentDataset.info.data_type === "text" ? "предварительную обработку" : "санитизацию"}</p>
                            </button>
                        </div>


                    <UploadAnnotation  files={files} setFiles={setFiles} setValidFiles={setValidFiles} accept={false} accept_formats={accept_formats}/>

                    <div className="modal_upload_btns">
                        <button type="button" className="gt_btn_primary" onClick={handleUpload} disabled={!(files.length > 0)}>
                            <p className="gt_pt">{importMode ? "Импортировать" : "Сохранить"}</p>
                        </button>
                        <button type="button" className="gt_btn_secondary" onClick={handleClose}>
                            <p className="gt_pt">Отмена</p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UploadModal;
