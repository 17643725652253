import "../../AdminDatasetsComponent/RightTable/RightTableComponent.scss"
import "./FolderInfoComponent.scss"
import IconPlus from "../../../../../../assets/icons/icon_plus_wt.svg"
import IconError from "../../../../../../assets/icons/icon_log_error.svg"
import IconImport from "../../../../../../assets/icons/import.svg"
import {useDispatch, useSelector} from "react-redux";
import {
    setFileUrlAction,
    setFolderFilesAction,
    showUploadModalAction
} from "../../../../../../store/reducers/folderReducer";
import {useEffect} from "react";
import {
    downloadPreviewFilesAction,
    getFilesAction
} from "../../../../../../store/actions/FolderAction";
import {activeImportModeAction} from "../../../../../../store/reducers/appReducer";
import { Tooltip } from 'react-tooltip'

function FolderInfoComponent(){
    const dispatch = useDispatch()

    const {currentFolder,files,fileUrl} = useSelector(store=>store.folder)
    const {currentDataset} = useSelector(store=>store.dataset)
    useEffect(()=>{
        async function getData(){
            if(currentFolder){
                //dispatch(setFileUrlAction([]))
                dispatch(setFolderFilesAction([]))
                await dispatch(getFilesAction(currentDataset._id,currentFolder.info.name))
            }
        }
        getData()

    },[currentFolder])

    useEffect(()=>{
        async function getFiles() {
            if(currentFolder && files.length > 0) {
                dispatch(downloadPreviewFilesAction(currentDataset._id, currentFolder.info.name, files))
            }
        }
        getFiles()

    },[files,currentDataset])

    let count_files  = 0
    let count_annotations  = 0

    if (currentFolder){
        if(currentFolder.files_per_cat){
            if (currentFolder.files_per_cat.length > 0){
                currentFolder.files_per_cat.forEach(file => {
                    count_files = count_files + file.count
                })
            }

            if (currentFolder.annotations_per_cat.length > 0){
                currentFolder.annotations_per_cat.forEach(file => {
                    count_annotations = count_annotations + file.count
                })
            }
        }

    }

    function handleImport(){
        dispatch(showUploadModalAction())
        dispatch(activeImportModeAction())
    }

    return(
        <div className="right_col folder">
            <div className="folder_row">
                {currentDataset.roles.includes("Поставщик") &&
                    <div className="upload_btn_group">
                        <button type="button" className="gt_btn_primary" onClick={()=>dispatch(showUploadModalAction())}>
                            <img src={IconPlus} alt="plus"/>
                            <p className="gt_pt">Добавить файлы</p>
                        </button>
                        <button type="button" onClick={handleImport}
                                data-tooltip-id="import-tooltip"
                                data-tooltip-content="Импортировать данные"
                                data-tooltip-place="top" >
                            <img src={IconImport} alt="plus"/>
                        </button>
                        <Tooltip id="import-tooltip" className="gt_tooltip" border="1px solid #D2DFFB"/>
                    </div>
                }

            </div>
            {currentDataset.info.data_type === "photo" ?
                <div className="preview_row">
                    <p className="gt_h4">Превью:</p>
                    {fileUrl.length > 0 ?
                        <div className="preview_row_img_block">
                            {fileUrl.slice(0,3).map(((file,i) => <img key={i+"img"} src={URL.createObjectURL(file)} className="preview_row_img" alt="preview"/> ))}
                        </div>
                        :
                        <div className="preview_row_img_block">
                            <div className="preview_row_empty_img"/>
                            <div className="preview_row_empty_img"/>
                            <div className="preview_row_empty_img"/>
                        </div>
                    }
                </div>
                : ""
            }

            <div className="processed_row">
                <p className="gt_h4">Обработано:</p>
                {currentFolder ? <div className="gt_flex_row">{currentFolder.uploaded < currentFolder.annotated ? <img src={IconError} alt="error"/> : ""}<p className="gt_pt">{currentFolder.annotated} / {currentFolder.uploaded}</p></div> : <p className="gt_pt gt_th">Нет данных</p>}
            </div>

            {
                /*
                <div className="processed_row">
                    <p className="gt_h4">Дата последнего редактирования:</p>
                    <p className="gt_pt gt_th">Нет данных</p>
                </div>

                 */
            }

            <div className="processed_row">
                <p className="gt_h4">Классы набора данных:</p>
                {currentFolder ?
                    <div className="detail_processing">
                        <div className="cat_block">
                            <div className="class_detail class_detail_header">
                                <p className="gt_pt gt_th ">Имя класса</p>
                                <p className="gt_pt gt_th center">Файлов</p>
                                <p className="gt_pt gt_th center">Разметок</p>
                            </div>
                        </div>

                        <div className="cat_block">
                            <div>
                                {
                                    currentDataset.info.categories.filter(cat => !cat.is_default).map(cat => (
                                        <div className="class_detail" key={cat.name + cat.color}>
                                            <p className="gt_pt">{cat.name}</p>
                                            <div className="class_detail_files">
                                                <p className="gt_pt item">{currentFolder.files_per_cat.filter(file => file.id === cat.id).length > 0 ? currentFolder.files_per_cat.filter(file => file.id === cat.id)[0].count : "0"}</p>
                                                <p className="gt_pt gt_th item">/</p>
                                                <p className="gt_pt ">{count_files > 0  && currentFolder.files_per_cat.filter(file => file.id === cat.id).length > 0 ? Math.round(Number(currentFolder.files_per_cat.filter(file => file.id === cat.id)[0].count) / count_files * 100)+"%" : "0%" }</p>
                                            </div>
                                            <div className="class_detail_files">
                                                <p className="gt_pt item">{currentFolder.annotations_per_cat.filter(file => file.id === cat.id).length > 0 ? currentFolder.annotations_per_cat.filter(file => file.id === cat.id)[0].count : "0"}</p>
                                                <p className="gt_pt gt_th item">/</p>
                                                <p className="gt_pt">{count_annotations > 0  && currentFolder.annotations_per_cat.filter(file => file.id === cat.id).length > 0 ? Math.round(Number(currentFolder.annotations_per_cat.filter(file => file.id === cat.id)[0].count) / count_annotations * 100)+"%" : "0%" }</p>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    : <p className="gt_pt gt_th">Нет данных</p>}

            </div>

        </div>
    )
}

export default FolderInfoComponent;
