import "./Room.scss";
import RoomSearch from "./RoomStatus/RoomSearch";
import RoomBusy from "./RoomStatus/RoomBusy";
import RoomNotification from "./RoomStatus/RoomNotification";
import RoomError from "./RoomStatus/RoomError";
import RoomFree from "./RoomStatus/RoomFree";



function Room () {

    const queryParams = new URLSearchParams(window.location.search);
    const status = queryParams.get('status');



    return (
        <div className="room">
            {
                status === "search" ? <RoomSearch /> :
                status === "busy"   ? <RoomBusy /> :
                status === "notification"   ? <RoomNotification /> :
                status === "free"   ? <RoomFree /> :
                status === "error"   ? <RoomError /> : ""

            }
        </div>
    )
}

export default Room;
