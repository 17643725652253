import { apiConfig } from '../../utils/apiConfig'
import axios from "axios";
import {addLogsAction} from "../reducers/appReducer";
import {
    activatePublishIntervalAction,
    disableFolderModeAction,
    setCurrentDatasetAction,
    setDatasetsAction,
    setFilesAction, setPublishEventAction,
    setValidationsAction,
    showPublishProgressAction,
} from "../reducers/datasetReducer";
import fileDownload from 'js-file-download';
import authHeader from "./userAction";


export const getDatasetAction = (currentDataset="") => {
    return async (dispatch) => {
        try {
            const response = await axios.get('admin/datasets/', {
                headers: await authHeader(),
                ...apiConfig,
            })
            dispatch(setDatasetsAction(response.data.datasets))
            if (!currentDataset){
                dispatch(setCurrentDatasetAction(response.data.datasets[0]))
                dispatch(getCurrentDatasetAction(response.data.datasets[0]._id))
            }
        } catch (e) {
            dispatch(addLogsAction({status:"error",time:Date.now(),text:e.message}))
        }
    }
}


export const createDatasetAction = (dataset,files) => {
    return async (dispatch) => {
        try {
            const response = await axios.post('admin/datasets/',
                dataset ,
                {
                    headers: await authHeader(),
                    ...apiConfig,
                })
            response.data.logs.map(log=>(
                dispatch(addLogsAction(log))
            ))

            files.map(file => dispatch(uploadAnnotationAction(file,response.data._id)))
        } catch (e) {
            dispatch(addLogsAction({status:"error",time:Date.now(),text:e.message}))
        }
    }
}

export const uploadAnnotationAction = (file,dataset_id) => {
    return async (dispatch) => {
        try {
            const formData = new FormData()
            formData.append('file', file)

            await axios.post('admin/attachments/'+dataset_id,
                formData ,
                {
                    headers: await authHeader(),
                    ...apiConfig,
                })
        } catch (e) {
            dispatch(addLogsAction({status:"error",time:Date.now(),text:e.message}))
        }
    }
}

export const getAnnotationAction = (dataset_id) => {
    return async (dispatch) => {
        try {
            const response = await axios.get('admin/attachments/'+dataset_id, {
                headers: await authHeader(),
                ...apiConfig,
            })
            dispatch(setFilesAction(response.data.annotations))
        } catch (e) {
            dispatch(addLogsAction({status:"error",time:Date.now(),text:e.message}))
        }
    }
}


export const downloadAnnotationAction = (dataset_id,file) => {
    return async (dispatch) => {
        try {
            const response = await axios.get('admin/attachments/'+dataset_id+"/"+file, {
                headers: await authHeader(),
                ...apiConfig,
                responseType: 'blob',
            })
            fileDownload(response.data, file)
        } catch (e) {
            dispatch(addLogsAction({status:"error",time:Date.now(),text:e.message}))
        }
    }
}

export const deleteDatasetAction = (dataset_id) => {
    return async (dispatch) => {
        try {
            await axios.delete('admin/datasets/'+dataset_id, {
                headers: await authHeader(),
                ...apiConfig,
            })
            dispatch(getDatasetAction())
        } catch (e) {
            dispatch(addLogsAction({status:"error",time:Date.now(),text:e.message}))
        }
    }
}

export const updateDatasetAction = (dataset,dataset_id) => {
    return async (dispatch) => {
        try {
            const response = await axios.put('admin/datasets/'+dataset_id,
                dataset ,
                {
                    headers: await authHeader(),
                    ...apiConfig,
                })
            response.data.logs.map(log=>(
                dispatch(addLogsAction(log))
            ))

        } catch (e) {
            dispatch(addLogsAction({status:"error",time:Date.now(),text:e.message}))
        }
    }
}


export const deleteAnnotationAction = (dataset_id,file_name) => {
    return async (dispatch) => {
        try {
            await axios.delete('admin/attachments/'+dataset_id+"/"+file_name, {
                headers: await authHeader(),
                ...apiConfig,
            })
        } catch (e) {
            dispatch(addLogsAction({status:"error",time:Date.now(),text:e.message}))
        }
    }
}

export const createRoom = (dataset_id) => {
    return async (dispatch) => {
        try {
            const response = await axios.post('admin/rooms/'+dataset_id,
                {},
                {
                    headers: await authHeader(),
                    ...apiConfig,
                })
            localStorage.setItem("room_id",response.data.room_id)
            window.location.href = "/room?status=free";
        } catch (e) {
            dispatch(addLogsAction({status:"error",time:Date.now(),text:e.message}))
            dispatch(disableFolderModeAction())
            localStorage.removeItem("current_dataset")
            localStorage.removeItem("folder_mode")
            window.location.href = "/room?status=error";
        }
    }
}

export const getValidationsAction = (dataset_id) => {
    return async (dispatch) => {
        try {
            const response = await axios.get('admin/validations/'+dataset_id, {
                headers: await authHeader(),
                ...apiConfig,
            })
            dispatch(setValidationsAction(response.data.validations))

        } catch (e) {
            dispatch(addLogsAction({status:"error",time:Date.now(),text:e.message}))
        }
    }
}


export const updateValidationAction = (dataset_id,validations) => {
    return async (dispatch) => {
        try {
            const response = await axios.put('admin/validations/'+dataset_id,
                {validations : validations} ,
                {
                    headers: await authHeader(),
                    ...apiConfig,
                })

        } catch (e) {
            dispatch(addLogsAction({status:"error",time:Date.now(),text:e.message}))
        }
    }
}


export const getCurrentDatasetAction = (dataset_id) => {
    return async (dispatch) => {
        try {
            const response = await axios.get('admin/datasets/'+dataset_id, {
                headers: await authHeader(),
                ...apiConfig,
            })
            dispatch(setCurrentDatasetAction(response.data))

        } catch (e) {
            dispatch(addLogsAction({status:"error",time:Date.now(),text:e.message}))
        }
    }
}


export const publishDatasetAction = (dataset_id) => {
    return async (dispatch) => {
        try {
            const response = await axios.get('admin/publish/'+dataset_id, {
                headers: await authHeader(),
                ...apiConfig,
            })
            dispatch(showPublishProgressAction())
            dispatch(activatePublishIntervalAction())
            dispatch(setPublishEventAction(response.data.event_id))

            dispatch(addLogsAction(response.data.logs[0]))
        } catch (e) {
            dispatch(addLogsAction({status:"error",time:Date.now(),text:e.message}))
        }
    }
}
