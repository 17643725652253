import "./RoomStatus.scss";
import Busy from "../../../assets/icons/busy.svg";




function RoomSearch () {

    return (
        <div className="room-status room-busy">
            <div className="room-busy_content">
                <img className="room-busy_busy-img" src={Busy} alt="Логотип"/>
                <div className="room-text">
                    <p className="gt_h4">
                        Извините, пока все комнаты заняты.
                    </p>
                    <p className="gt_pt">
                        Отправить вам уведомление, когда появится свободное место?
                    </p>
                </div>

            </div>
            <div className="room-busy_btn_group">
                <button className="gt_btn_secondary">
                    <span className="gt_pt">Нет, зайду позже</span>
                </button>
                <button className="gt_btn_primary">
                    <span className="gt_pt">Да, отправьте</span>
                </button>
            </div>
        </div>
    )
}

export default RoomSearch;
