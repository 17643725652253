import "./DatasetChecks.scss"
import {useEffect, useState} from "react";
import CheckboxGosteh from "../../../../../../CheckboxGosteh/CheckboxGosteh";
import IconError from "../../../../../../../assets/icons/icon_log_error.svg";
import IconSuccess from "../../../../../../../assets/icons/icon_log_success.svg";

import {useSelector} from "react-redux";

function DatasetChecks({setOpt}){
    const {currentDataset,validations} = useSelector(store=>store.dataset)
    const [checks,setChecks] = useState([])

    useEffect(()=>{
        if (validations.length > 0){
            let list = currentDataset.validations.map(ds_val => {
                let opt = validations.filter(val => val._id === ds_val._id)
                return  {
                    name:ds_val._id,
                    label:opt.length > 0 ? opt[0].name : "",
                    value:false,
                    done: ds_val.done
                    }
                }
            )
            setChecks(list)
        }


    },[currentDataset,validations])



    function changeCheck(name,value){
        let new_checks = checks
        let index = new_checks.findIndex(p => p.name === name);
        new_checks[index].value = value
        setChecks(new_checks)
        setOpt(new_checks)
    }

    return(
        <div className="ds_checks_block">
            {checks.map(check => (
                <div key={check.name} className="ds_checks_item">
                    {check.done === null ? <div style={{width:"16px"}}></div> :
                        <img src={check.done ? IconSuccess : IconError} alt="status" style={{width:"16px"}}/>
                    }

                    <CheckboxGosteh
                        key={check.name}
                        label={check.label}
                        check_value={check.value}
                        onChange={changeCheck}
                        name={check.name}
                    />
                </div>
            ))}
        </div>
    )
}






export default DatasetChecks;
