import "./RoomStatus.scss";
import Sent from "../../../assets/icons/pic_email_sent.svg";




function RoomNotification () {

    return (
        <div className="room-status room-notification">
            <div className="room-notification_content">
                <img className="room-notification_sent-img" src={Sent} alt="Логотип"/>
                <div className="room-text">
                    <p className="gt_h4">
                        Уведомление отправлено на <span className="room-notification_mail">example@gmail.com</span>
                    </p>
                    <p className="gt_pt room-notification_descript">
                        Этот адрес электронной почты привязан к Вашему аккаунту на Госуслугах.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default RoomNotification;
