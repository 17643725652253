const SET_USER_INFO = "SET_USER_INFO"
const UPDATE_TOKEN = "UPDATE_TOKEN"


const user_info = JSON.parse(localStorage.getItem("user_info"))

const initialState = {
    userInfo: user_info,
}

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_INFO:
            return { ...state, userInfo: action.payload }
        case UPDATE_TOKEN:
            return { ...state,userInfo: {...state.userInfo,access_token : action.payload}}
        default:
            return state
    }
}

export const setUserInfoAction = (user) => ({ type: SET_USER_INFO,payload: user })
export const updateAccessTokenAction = (access_token) => ({ type: UPDATE_TOKEN,payload: access_token })


