import "./TextAnnotation.scss"
import "../Annotation/Annotation.scss"
import "../MyRoom/Administrator/CreateDataset/CreateDatasetAdministrator.scss";
import IconBack from "../../assets/icons/icon-arrow-left.svg";
import IconSearch from "../../assets/icons/icon_search.svg";
import IconFile from "../../assets/icons/file_icon.svg";
import IconFileMark from "../../assets/icons/file_marked.svg";
import {COLORS} from "../../utils/color_for_classes";
import DeleteModal from "../DeleteModal/DeleteModal";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {setCurrentFileAction, setCurrentFileAnnotationsAction} from "../../store/reducers/folderReducer";
import {showDeleteModalAction} from "../../store/reducers/appReducer";
import {useNavigate} from "react-router-dom";
import {
    addMarkupAction,
    downloadFilesAction,
    getFileMurkupsAction,
    getFilesAction
} from "../../store/actions/FolderAction";
import {TextAnnotator} from 'react-text-annotate'
import uniqid from "uniqid";

function TextAnnotation(){
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const {showDeleteModal} = useSelector(store=>store.app)
    const {currentFolder,files,currentFile,fileUrl,currentFileAnnotations} = useSelector(store=>store.folder)
    const {currentDataset} = useSelector(store=>store.dataset)
    const [search,setSearch] = useState("")
    const [value,setValue] = useState("");
    const [activeCategory,setActiveCategory] = useState("0");

    const [summary, setSummary] = useState("")

    const [text,setText] = useState("");

    useEffect(()=>{
        if (fileUrl.length > 0){
            let reader = new FileReader();
            reader.onload = function() {
                setText(reader.result)
            }
           reader.readAsText(fileUrl[0]);
        }
    },[fileUrl])

    useEffect(() => {
        if(currentFileAnnotations){
            let annotations = currentFileAnnotations.filter(an => an.type === "TextSelector").map(an => {
                let data = {
                    "start" : Number(an.value[0]),
                    "end" : Number(an.value[1]),
                    "tag" : currentDataset.info.categories.filter(cat => cat.id === an.category.id)[0]["name"],
                    "color" : COLORS.filter(color => color.color === currentDataset.info.categories.filter(cat => cat.id === an.category.id)[0]["color"])[0].opacity,
                }
                return data
            })
            let sum = currentFileAnnotations.filter(an => an.type === "TextSummary")
            if (sum.length > 0){
                setSummary(sum[0].meta)
            }
            setValue(annotations)
        }
    }, [currentFileAnnotations]);

    useEffect(()=>{
        if (currentFile){
            dispatch(downloadFilesAction(currentDataset._id, currentFolder.info.name, currentFile))
            dispatch(getFileMurkupsAction(currentFile))
        }
    },[currentFile])

    useEffect(()=>{
        if(files.length > 0){
            dispatch(setCurrentFileAction(files[0].name))
        }
    },[])

    function handleActiveFile(file){

        if(currentFile !== file){
            dispatch(setCurrentFileAction(file))
            dispatch(getFilesAction(currentDataset._id,currentFolder.info.name))
            setValue("")
            setSummary("")
        }
    }

    function handleBack(){
        navigate("/my_room_admin");
    }

    function handleDelete(){
        dispatch(showDeleteModalAction())
    }

    function selectCategory(id) {
        setActiveCategory(id)
    }

    function handleConfirm() {
        if (value.length > 0 || summary){
            let mark  = value.map(val => {
                let data = {
                    _id : uniqid()+Date.now(),
                    type : "TextSelector",
                    value : [val.start.toString(), val.end.toString()],
                    category: {
                        id:currentDataset.info.categories.filter(cat => cat.name === val.tag)[0]["id"]
                    },
                }
                return data
            })
            if (summary){
                mark.push({
                    _id : uniqid()+Date.now(),
                    type : "TextSummary",
                    value : ["0", text.length.toString()],
                    category: {
                        id:"99999999999"
                    },
                    meta : summary
                })
            }

            setValue("")
            setSummary("")
            dispatch(addMarkupAction(currentDataset._id,currentFolder.info.name,currentFile,mark))
            dispatch(setCurrentFileAnnotationsAction(""))
            let new_files = Array.from(files)
            let index = new_files.findIndex(el => el.name === currentFile);


            if(index !== files.length-1){
                dispatch(setCurrentFileAction(files[index+1].name))
            }else {
                setTimeout(()=>dispatch(getFileMurkupsAction(currentFile)),100)

            }
            dispatch(getFilesAction(currentDataset._id,currentFolder.info.name))

        }
    }

    return (currentFolder &&
        <div className="ts_annotate">
            <div className="anno_content">
                <div className="adm_mr">
                    <div className="adm_mr_btn_group">
                        <button type="button" onClick={handleBack} className="adm_mr_btn">
                            <img src={IconBack} alt="Logo"/>
                            <p className="gt_h4 gt_gb">{currentDataset.info.name}</p>
                            <p className="gt_h4 "> / {currentFolder.info.name}</p>
                        </button>
                    </div>

                    <div className="search">
                        <label htmlFor="search">
                            <img src={IconSearch} alt="search"/>
                        </label>
                        <input placeholder="Поиск по имени файла" id="search" className="gt_pt" value={search} onChange={(e)=>setSearch(e.target.value)}/>
                    </div>

                    <div className="anno_table">
                        <div className="files_block">
                            <div className="files_block_header">
                                <p className="gt_pt"><span className="gt_th">Обработано: </span>{files.filter(file=>file.annotated).length} / {files.length}</p>
                            </div>
                            <div className="files_block_list">
                                {files.filter(file => file.name.toLowerCase().includes(search.toLowerCase())).map(file => (
                                    <button type="button" className={`gt_flex_row files_block_item ${file.name === currentFile ? "active" : ""}`} key={file.name} onClick={()=>handleActiveFile(file.name)}>
                                        <img src={file.annotated ? IconFileMark : IconFile} alt="file"/>
                                        <p className="gt_pt files_block_item_text">{file.name}</p>
                                        <div className="mod_circles">
                                            {file.modifiers.slice(-1).map((mod,i) =>
                                                <div className="name_circle" style={{left:"-"+15*Number(i)+"px"}} key={file.name+mod}>
                                                    <p className="gt_ps">{mod}</p>
                                                </div>
                                            )}
                                        </div>
                                    </button>
                                ))}
                            </div>
                        </div>
                        <div className="annotation_main">
                            <div className="annotation_block">
                                <div className="text_annotation">
                                    <TextAnnotator
                                        style={{
                                            lineHeight: 1.5,
                                            whiteSpace: "pre-line"
                                        }}
                                        content={text}
                                        value={value}
                                        onChange={value => setValue(value)}
                                        getSpan={span => ({
                                            ...span,
                                            tag: currentDataset.info.categories.filter(cat => cat.id === activeCategory)[0]["name"],
                                            color: COLORS.filter(color => color.color === currentDataset.info.categories.filter(cat => cat.id === activeCategory)[0]["color"])[0].opacity,

                                        })}
                                    />
                                </div>

                                <div className="class_btn_block">
                                    {currentDataset.info.categories.map((cat, i) =>
                                        <button type="button" onClick={() => selectCategory(cat.id)} key={cat.id}
                                                className={`cat_button ${cat.id === activeCategory ? "active" : ""}`}>
                                            <div className="class_element">
                                                <div className="main_color " style={{backgroundColor: cat.color}}/>
                                                <div className="gt_pt op_color"
                                                     style={{backgroundColor: COLORS.filter(color => color.color === cat.color)[0].opacity}}>
                                                    <p>{cat.name} <span className="gt_th">{i + 1}</span></p>
                                                </div>
                                            </div>
                                        </button>
                                    )}
                                </div>
                                <div className="summary_block">
                                    <label className="gt_ih"htmlFor="summary">
                                        Информация о тексте
                                    </label>
                                    <textarea id="summary" rows={3} className="summary_edit gt_pt shadow"
                                              value={summary} onChange={(e) => setSummary(e.target.value)}/>
                                </div>

                                <div className="confirm_btn">
                                    <button type="button" className="gt_btn_primary"
                                            onClick={handleConfirm}>Подтвердить
                                    </button>
                                    <button type="button" className="gt_btn_primary del_btn"
                                            onClick={handleDelete}>Удалить
                                    </button>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
                {showDeleteModal && <DeleteModal />}
            </div>
        </div>

    )
}

export default TextAnnotation;
