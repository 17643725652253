import "./DatasetList.scss"
import DatasetIcon from "../../../../../../assets/icons/folder.svg";
import FolderIcon from "../../../../../../assets/icons/file.svg";
import CopyIcon from "../../../../../../assets/icons/icon_copy.svg";
import NewDatasetIcon from "../../../../../../assets/icons/icon_new_dataset.svg";
import WipIcon from "../../../../../../assets/icons/icon_wip.svg";
import ReadyIcon from "../../../../../../assets/icons/icon_ready.svg";
import DoneIcon from "../../../../../../assets/icons/icon_done.svg";
import {
    disablePublishIntervalAction,
    disableValidIntervalAction,
    enableFolderModeAction, hidePublishProgressAction, hideValidationProgressAction,
    setCurrentDatasetAction, setPublishEventAction,
    setValidationEventAction
} from "../../../../../../store/reducers/datasetReducer";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentFolderAction, setFileUrlAction} from "../../../../../../store/reducers/folderReducer";
import {useNavigate} from "react-router-dom";
import {getCurrentDatasetAction} from "../../../../../../store/actions/datasetAction";
import {Tooltip} from "react-tooltip";




function DatasetList ({datasets,activeDataset,setActiveDataset,type}) {

    const navigate = useNavigate();
    const {currentDataset} = useSelector(store=>store.dataset)
    const dispatch = useDispatch()


    function handleActive(ds){
        if(type==="dataset"){
            dispatch(getCurrentDatasetAction(ds._id))
            setActiveDataset(ds._id)
            dispatch(setValidationEventAction(""))
            dispatch(hideValidationProgressAction())
            dispatch(disableValidIntervalAction())
            dispatch(setPublishEventAction(""))
            dispatch(hidePublishProgressAction())
            dispatch(disablePublishIntervalAction())
        }
        else {
            dispatch(setCurrentFolderAction(ds))
            setActiveDataset(ds.info.name)
        }

    }

     function handleDoubleClick(ds){
        if (type === "dataset"){
            dispatch(setFileUrlAction([]))
            dispatch(setCurrentDatasetAction(ds))
            dispatch(enableFolderModeAction())
            localStorage.setItem("current_dataset",JSON.stringify(ds))
            localStorage.setItem("folder_mode","true")
            navigate("/room?status=search")
        }
        else if (type === "folder"){
            dispatch(setCurrentFolderAction(ds))
            setActiveDataset(ds.info.name)
            if (currentDataset.roles.includes("admin") || currentDataset.roles.includes("Разметчик")){
                if (currentDataset.info.data_type === "photo"){
                    setTimeout(() => {
                        navigate("/annotation");
                    }, 300);

                }
                else if (currentDataset.info.data_type === "time_series"){
                    navigate("/ts_annotate");
                }
                else if (currentDataset.info.data_type === "text"){
                    navigate("/text_annotate");
                }
            }
        }

    }

    return (
        <div className="dataset_list">
            {datasets.map(ds => (
                <button type="button"  className={`dataset_list_item ${activeDataset === ds._id || activeDataset === ds.info.name ? "active" : ""}`} key={ds._id} onClick={()=>handleActive(ds)} onDoubleClick={()=>handleDoubleClick(ds)}>
                    <div className="dataset_list_name">
                        <img src={type === "dataset" ? DatasetIcon : FolderIcon} alt="Folder icon"/>
                        <p className="gt_pt dataset_list_name_text">{ds.info.name}</p>
                        {type === "dataset" ?
                        <div  className="dataset_list_name_image" onClick={() => {navigator.clipboard.writeText(ds._id)}}
                        ><img src={CopyIcon} alt="Copy icon"/></div>
                            : ""}
                    </div>
                    <div className="dataset_list_processing">
                        {ds.modifiers.slice(0,2).map((mod,i)=>
                            <div className="name_circle"
                                 style={{left:"-"+15*Number(i)+"px"}}
                                 data-tooltip-id={`modifiers-tooltip-${ds._id}`}
                                 data-tooltip-place="top">
                                <p className="gt_ps">{mod}</p>
                            </div>
                        )}
                        {
                            ds.modifiers.length === 3 ?
                                <div className="name_circle"
                                     style={{left:"-"+15*2+"px"}}
                                     data-tooltip-id={`modifiers-tooltip-${ds._id}`}
                                     data-tooltip-place="top" >
                                    <p className="gt_ps">{ds.modifiers[2]}</p>
                                </div> :
                            ds.modifiers.length > 3 ?
                                <div className="name_circle"
                                     style={{left:"-"+15*2+"px"}}
                                     data-tooltip-id={`modifiers-tooltip-${ds._id}`}
                                     data-tooltip-place="top" >
                                    <p className="gt_ps">{ds.modifiers.length - 2}+</p>
                                </div> : ""
                        }
                        {ds.modifiers.length > 1 ?
                            <Tooltip id={`modifiers-tooltip-${ds._id}`} className="gt_tooltip" border="1px solid #D2DFFB">
                                <div className="modifiers_tooltips">
                                    {ds.modifiers.map(mod=>
                                            <div className="name_circle"
                                                 data-tooltip-id={`modifiers-tooltip-${ds._id}`}
                                                 data-tooltip-place="top" >
                                                <p className="gt_ps">{mod}</p>
                                            </div>
                                        )}
                                </div>
                            </Tooltip>
                            : ""
                        }
                    </div>
                    <div className="dataset_list_status">
                        {type === "dataset" ?
                            <>
                                <img src={ds.status === "new" ? NewDatasetIcon :
                                          ds.status === "ready" ? ReadyIcon :
                                          ds.status === "wip" ? WipIcon :
                                          ds.status === "done" ?  DoneIcon : NewDatasetIcon} className="status_image" alt="dataset icon"/>
                                <p className="gt_pt">
                                    {
                                        ds.status === "new" ? "Новый набор данных" :
                                        ds.status === "ready" ? "Готов к обработке" :
                                        ds.status === "wip" ? "В процессе обработки" :
                                        ds.status === "done" ?  "Обработка завершена" : "Набор данных"
                                    }
                                </p>
                            </>
                            : <p className="gt_pt gt_gr files_count">{ds.annotated}/{ds.uploaded}</p>
                        }

                    </div>
                </button>
                )
            )}
        </div>
    )
}

export default DatasetList;
