import axios from "axios";
import {apiConfig} from "../../utils/apiConfig";
import {addLogsAction} from "../reducers/appReducer";
import {setUserInfoAction, updateAccessTokenAction} from "../reducers/userReducer";
import {FRONT_URL} from "../../utils/constants";

axios.defaults.withCredentials = true

export default async function authHeader() {
    let user = JSON.parse(localStorage.getItem('user_info'));

    if (user && user.access_token) {
        if (user.expiration_timestamp <= Date.now()){
            try {
                const response = await axios.get('auth/refresh?refresh_token='+user.refresh_token, {
                    ...apiConfig,
                })
                user = response.data

                localStorage.setItem("user_info",JSON.stringify(user))
            }catch (e){
                localStorage.removeItem("user_info")
                window.location.href = "/login";
            }
        }
        return { Authorization: 'Bearer ' + user.access_token };
    } else {
        window.location.href = "/login";
    }
}

export const getAuthUrlAction = () => {
    return async (dispatch) => {
        try {
            localStorage.clear()
            const response = await axios.get('auth/get-auth-url?redirect_url='+FRONT_URL+'success_login', {
                ...apiConfig,
            })
            window.location.href = response.data.result.url;

        } catch (e) {
            dispatch(addLogsAction({status:"error",time:Date.now(),text:e.message}))
        }
    }
}

export const getUserDataAction = (code) => {
    return async (dispatch) => {
        try {
            const response = await axios.post('auth/user-data-chain', {
                code:code,
            },
            {
                ...apiConfig,
            })
            localStorage.setItem("user_info",JSON.stringify(response.data))
            dispatch(setUserInfoAction(response.data))

        } catch (e) {
            dispatch(addLogsAction({status:"error",time:Date.now(),text:e.message}))
        }
    }
}

export const refreshAccessTokenAction = () => {
    return async (dispatch) => {
        try {
            const user = JSON.parse(localStorage.getItem('user_info'));
            const refresh_token = user.refresh_token

            const response = await axios.get('auth/refresh?refresh_token='+refresh_token, {
                headers: await authHeader(),
                ...apiConfig,
            })
            localStorage.setItem("user_info",JSON.stringify(response.data))
            dispatch(setUserInfoAction(response.data))
        } catch (e) {
            localStorage.removeItem("user_info")
            window.location.href = "/login";
            //dispatch(addLogsAction({status:"error",time:Date.now(),text:e.message}))
        }
    }
}


export const selectOrganizationAction = (oid) => {
    return async (dispatch) => {
        try {
            const user = JSON.parse(localStorage.getItem('user_info'));
            const refresh_token = user.refresh_token
            const response = await axios.post('auth/select-organization?refresh_token='+refresh_token, {
                    oid:oid,
                },
                {
                    headers:await authHeader(),
                    ...apiConfig,
                })
            let up_user = {...user,access_token:response.data.access_token}
            localStorage.setItem("user_info",JSON.stringify(up_user))
            dispatch(updateAccessTokenAction(response.data.access_token))
            dispatch(refreshAccessTokenAction())

        } catch (e) {
            dispatch(addLogsAction({status:"error",time:Date.now(),text:e.message}))
        }
    }
}

export const logoutUserAction = () => {
    return async (dispatch) => {
        try {
            const user = JSON.parse(localStorage.getItem('user_info'));
            const refresh_token = user.refresh_token

            const response = await axios.get('auth/logout?refresh_token='+refresh_token, {
                headers: await authHeader(),
                ...apiConfig,
            })
            localStorage.clear()
            dispatch(setUserInfoAction(""))
            window.location.href = response.data.url_logout;
        } catch (e) {
            dispatch(addLogsAction({status:"error",time:Date.now(),text:e.message}))
        }
    }
}
