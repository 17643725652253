import "./RoomStatus.scss";
import Error from "../../../assets/icons/pic_service_unavailible.svg";
import {useNavigate} from "react-router-dom";




function RoomError () {
    const navigate = useNavigate();

    function handleBack(){

        navigate("/my_room_admin")
    }
    return (
        <div className="room-status room-error">
            <div className="room-error_content">
                <img className="room-error_error-img" src={Error} alt="Логотип"/>
                <div className="room-text">
                    <p className="gt_h4">
                        Извините, сервис сейчас недоступен.
                    </p>
                    <p className="gt_pt">
                        Пожалуйста, повторите попытку позже.
                    </p>
                </div>
            </div>
            <button className="gt_btn_primary" onClick={handleBack}>
                <span className="gt_pt">Найти комнату</span>
            </button>
        </div>
    )
}

export default RoomError;
