import "./TimeSeries.scss"
import "../Annotation/Annotation.scss"
import IconBack from "../../assets/icons/icon-arrow-left.svg";
import IconSearch from "../../assets/icons/icon_search.svg";
import IconFile from "../../assets/icons/file_icon.svg";
import IconFileMark from "../../assets/icons/file_marked.svg";
import {COLORS} from "../../utils/color_for_classes";
import DeleteModal from "../DeleteModal/DeleteModal";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {setCurrentFileAction} from "../../store/reducers/folderReducer";
import {showDeleteModalAction} from "../../store/reducers/appReducer";
import {useNavigate} from "react-router-dom";
import {FRONT_URL} from "../../utils/constants";
import {getFilesAction} from "../../store/actions/FolderAction";


function TimeSeries(){
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const {showDeleteModal} = useSelector(store=>store.app)
    const {currentFolder,files,currentFile} = useSelector(store=>store.folder)
    const {currentDataset} = useSelector(store=>store.dataset)
    const [search,setSearch] = useState("")
    const [frameUrl,setFrameUrl] = useState("")
    useEffect(()=>{
        if (currentFolder){
            let room_id = localStorage.getItem("room_id")
            let url = `${FRONT_URL}timeseries/?dataset=${currentDataset._id}&folder=${currentFolder.info.name}&fileId=${currentFile}&roomId=${room_id}`
            setFrameUrl(url)
        }

    },[currentFolder,currentDataset,currentFile])

    useEffect(()=>{
        if(files.length > 0){
            dispatch(setCurrentFileAction(files[0].name))
        }
    },[])

    function handleActiveFile(file){
        dispatch(setCurrentFileAction(file))
        dispatch(getFilesAction(currentDataset._id,currentFolder.info.name))
    }

    function handleBack(){
        navigate("/my_room_admin");
    }

    function handleDelete(){
        dispatch(showDeleteModalAction())
    }

    return (currentFolder &&
        <div className="ts_annotate">
            <div className="anno_content">
                <div className="adm_mr">
                    <div className="adm_mr_btn_group">
                        <button type="button" onClick={handleBack} className="adm_mr_btn">
                            <img src={IconBack} alt="Logo"/>
                            <p className="gt_h4 gt_gb">{currentDataset.info.name}</p>
                            <p className="gt_h4 "> / {currentFolder.info.name}</p>
                        </button>
                    </div>

                    <div className="search">
                        <label htmlFor="search">
                            <img src={IconSearch} alt="search"/>
                        </label>
                        <input placeholder="Поиск по имени файла" id="search" className="gt_pt" value={search} onChange={(e)=>setSearch(e.target.value)}/>
                    </div>

                    <div className="anno_table">
                        <div className="files_block">
                            <div className="files_block_header">
                                <p className="gt_pt"><span className="gt_th">Обработано: </span>{files.filter(file=>file.annotated).length} / {files.length}</p>
                            </div>
                            <div className="files_block_list">
                                {files.filter(file => file.name.toLowerCase().includes(search.toLowerCase())).map(file => (
                                    <button type="button" className={`gt_flex_row files_block_item ${file.name === currentFile ? "active" : ""}`} key={file.name} onClick={()=>handleActiveFile(file.name)}>
                                        <img src={file.annotated ? IconFileMark : IconFile} alt="file"/>
                                        <p className="gt_pt files_block_item_text">{file.name}</p>
                                        <div className="mod_circles">
                                            {file.modifiers.slice(-1).map((mod,i) =>
                                                <div className="name_circle" style={{left:"-"+15*Number(i)+"px"}} key={file.name+mod}>
                                                    <p className="gt_ps">{mod}</p>
                                                </div>
                                            )}
                                        </div>
                                    </button>
                                ))}
                            </div>
                        </div>
                        <div className="annotation_main">
                            <div className="annotation_block">
                                <iframe className="anno_frame" src={frameUrl} height={800} ></iframe>

                                <div className="class_btn_block" >
                                    {currentDataset.info.categories.map((cat,i) =>
                                        <div  key={cat.id} className="cat_button">
                                            <div className="class_element" >
                                                <div className="main_color " style={{backgroundColor: cat.color}}/>
                                                <div className="gt_pt op_color" style={{backgroundColor: COLORS.filter(color => color.color === cat.color)[0].opacity}}>
                                                    <p>{cat.name} <span className="gt_th">{i+1}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="confirm_btn">
                                    <button type="button" className="gt_btn_primary del_btn" onClick={handleDelete}>Удалить</button>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
                {showDeleteModal && <DeleteModal />}
            </div>
        </div>

    )
}

export default TimeSeries;
