import { useEffect, useRef, useState } from 'react';
import { Annotorious } from '@recogito/annotorious';
import {COLORS} from "../../utils/color_for_classes"
import "./Annotation.scss"
import "../MyRoom/Administrator/MyRoom/AdminDatasetsComponent/AdminDatasetsComponent.scss"
import '@recogito/annotorious/dist/annotorious.min.css';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import IconBack from "../../assets/icons/icon-arrow-left.svg";
import IconSearch from "../../assets/icons/icon_search.svg";
import IconWait from "../../assets/icons/wait.svg";
import IconFile from "../../assets/icons/file_icon.svg";
import IconFileMark from "../../assets/icons/file_marked.svg";
import IconEdit from "../../assets/icons/edit.svg";
import IconEditDisable from "../../assets/icons/edit_disable.svg";
import {
    setCurrentFileAction,
    setCurrentFileAnnotationsAction,
} from "../../store/reducers/folderReducer";
import {
    addMarkupAction,
    downloadFilesAction,
    getFileMurkupsAction,
    getFilesAction
} from "../../store/actions/FolderAction";
import {showDeleteModalAction} from "../../store/reducers/appReducer";
import DeleteModal from "../DeleteModal/DeleteModal";

function Annotation() {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const {showDeleteModal} = useSelector(store=>store.app)
    const {currentFolder,files,fileUrl,currentFile,currentFileAnnotations} = useSelector(store=>store.folder)
    const {currentDataset} = useSelector(store=>store.dataset)
    const [search,setSearch] = useState("")
    const [activeCategory,setActiveCategory] = useState("")
    const [reload,setReload] = useState(false)
    const [meta,setMeta] = useState("")
    const [editMeta,setEditMeta] = useState(false)

    // Ref to the image DOM element
    const imgEl = useRef();
    // The current Annotorious instance
    const [ anno, setAnno ] = useState();

    // Current drawing tool name
    const [ tool, setTool ] = useState('');


    let formatter = function(annotation) {
        if (currentDataset){
            if(annotation.bodies.filter(prop => prop.purpose === "category").length > 0){
                let category = annotation.bodies.filter(prop => prop.purpose === "category")[0].value
                if(currentDataset.info.categories.filter(cat => cat.id === category).length > 0){
                    let color = currentDataset.info.categories.filter(cat => cat.id === category)[0].color
                    let opacity = COLORS.filter(col => col.color === color)[0].opacity

                    let style = `stroke-width:2; stroke: ${color}; fill: ${opacity};`

                    return {
                        'style': style
                    }
                }

            }
        }

    }


    useEffect(()=>{
        //console.log("annot")
        if (currentFileAnnotations && anno){
            if (imgEl.current.id === "main"){
                let annotate = [];
                anno.clearAnnotations();
                if (currentFileAnnotations.length > 0){
                    if (currentFile === currentFileAnnotations[0].filename){
                        currentFileAnnotations.forEach(an => {
                            annotate.push({
                                "@context": "http://www.w3.org/ns/anno.jsonld",
                                "id": an._id,
                                "type": "Annotation",
                                "body": [ {
                                    "type": "TextualBody",
                                    "purpose": "category",
                                    "value": an.category.id
                                },
                                    {
                                        "type": "TextualBody",
                                        "purpose": "meta",
                                        "value": an.meta
                                    }],
                                "target": {
                                    "selector": {
                                        "type": an.type,
                                        "conformsTo": "http://www.w3.org/TR/media-frags/",
                                        "value": an.value
                                    }
                                }
                            })
                        })
                    }
                }

                if (annotate.length > 0){
                    let id = annotate.map(an=>an.id)
                    localStorage.setItem("anno_list",JSON.stringify(id))
                    anno.setAnnotations(annotate);
                }
            }
        }
    },[currentFileAnnotations,anno])



    useEffect( ()=>{
        //console.log("cahnge file")
        async function getData(){
            if(currentFolder && currentFile && anno){
                localStorage.removeItem("category")
                localStorage.removeItem("drawing_tool")
                setTool('rect')
                anno.setDrawingTool('rect')
                setActiveCategory("")
                setMeta("")
                localStorage.removeItem("anno_meta")
                localStorage.removeItem("anno_list")

                //await dispatch(setFileUrlAction([]))
                anno.clearAnnotations();
                await dispatch(downloadFilesAction(currentDataset._id, currentFolder.info.name, currentFile))
                await dispatch(getFileMurkupsAction(currentFile))
            }
        }
        getData()
    },[currentFile,anno])

    useEffect(()=>{

        if (files.length > 0){
            dispatch(setCurrentFileAction(files[0].name))
        }
        localStorage.removeItem("anno_list")

        if (files.length === 0 ){
            navigate("/my_room_admin");
        }
    },[])

    useEffect(() => {

        let annotorious = null;
        if (imgEl.current) {
                // Init
                annotorious = new Annotorious({
                    image: imgEl.current,
                    locale: 'auto',
                    fragmentUnit:"percent",
                    disableEditor: true,
                    formatter: formatter
                });

                annotorious.on('createSelection', async function(selection) {
                    let category = localStorage.getItem("category")
                    if (category){
                        selection.body = [{
                            type: 'TextualBody',
                            purpose: 'category',
                            value: category
                        },
                            {
                            type: 'TextualBody',
                            purpose: 'meta',
                            value: ""
                        }];
                    }
                    await annotorious.updateSelected(selection);
                    annotorious.saveSelected();

                });

                annotorious.on('selectAnnotation', async function(annotation, element) {
                    setMeta("")
                    localStorage.removeItem("anno_meta")
                    let dr_tool = localStorage.getItem("drawing_tool")
                    if (dr_tool === "delete"){
                        annotorious.removeAnnotation(annotation)

                        let anno_list = JSON.parse(localStorage.getItem("anno_list"))
                        if (anno_list){
                            anno_list = anno_list.filter(an => an !== annotation.id)
                        }
                        localStorage.setItem("anno_list",JSON.stringify(anno_list))
                    } else {
                        let category = localStorage.getItem("category")
                        let meta_data = annotation.body.filter(prop => prop.purpose === "meta")
                        if (category){
                            if (category !== annotation.body.filter(prop => prop.purpose === "category")[0].value){
                                annotation.body = annotation.body.map(body => {
                                    if (body.purpose === 'category'){
                                        body.value = category
                                    }
                                    return body
                                })
                                await annotorious.updateSelected(annotation);
                                annotorious.saveSelected();
                            } else {
                                if (meta_data.length > 0){
                                    localStorage.setItem("anno_meta",meta_data[0].value)
                                    setMeta(meta_data[0].value)
                                }
                            }
                        } else {
                            if (meta_data.length > 0){
                                localStorage.setItem("anno_meta",meta_data[0].value)
                                setMeta(meta_data[0].value)
                            }
                        }


                    }
                });

                annotorious.on('cancelSelected', async function(selection) {
                    let meta_data = localStorage.getItem("anno_meta")
                    if (meta_data){
                        selection.body = selection.body.map(body => {
                            if (body.purpose === 'meta'){
                                body.value = meta_data
                            }
                            return body
                        })
                        await annotorious.updateSelected(selection);
                        annotorious.saveSelected();
                    }
                });

                // Attach event handlers here
                annotorious.on('createAnnotation', annotation => {
                    let anno_list = JSON.parse(localStorage.getItem("anno_list"))
                    if (anno_list){
                        anno_list.push(annotation.id)
                    }else {
                        anno_list = [annotation.id]
                    }
                    localStorage.setItem("anno_list",JSON.stringify(anno_list))

                });

                annotorious.on('updateAnnotation', (annotation, previous) => {
                    //console.log('updated', annotation);
                });

                annotorious.on('deleteAnnotation', annotation => {
                    //console.log('deleted', annotation);

                });

                setAnno(annotorious);

                // Cleanup: destroy current instance
                return () => annotorious.destroy();

        }
    }, [reload]);




    // Toggles current tool + button label
    const toggleTool = (type) => {

        if(type === "delete"){
            setTool(type);
            localStorage.setItem("drawing_tool",type)
            localStorage.removeItem("category")
            setActiveCategory("")
        } else{
            setTool(type);
            anno.setDrawingTool(type);
            localStorage.setItem("drawing_tool",type)
        }


        if (anno){
            let anno_list = JSON.parse(localStorage.getItem("anno_list"))
            let new_anno = anno.getAnnotations().length
            if (anno_list){
                if(anno_list.length !== new_anno){
                    setReload(!reload)
                }
            }else{
                if (new_anno > 0){
                    setReload(!reload)
                }
            }
        }

    }

    const selectCategory = (category)=> {
        let drawing_tool = localStorage.getItem("drawing_tool")
        if (drawing_tool === "delete"){
            setTool("rect")
            anno.setDrawingTool("rect");
            localStorage.setItem("drawing_tool","rect")
        }

        setActiveCategory(category)
        localStorage.setItem("category",category)

        if (anno){
            let anno_list = JSON.parse(localStorage.getItem("anno_list"))
            let new_anno = anno.getAnnotations().length
            if (anno_list){
                if(anno_list.length !== new_anno){
                    setReload(!reload)
                }
            }else{
                if (new_anno > 0){
                    setReload(!reload)
                }
            }
        }
    }

    function handleActiveFile(file){

        if (anno){
            let anno_list = JSON.parse(localStorage.getItem("anno_list"))
            let new_anno = anno.getAnnotations().length
            if (anno_list){
                if(anno_list.length !== new_anno){
                    setReload(!reload)
                }
            }else{
                if (new_anno > 0){
                    setReload(!reload)
                }
            }
        }

        if(file !== currentFile){
            anno.clearAnnotations();
        }

        dispatch(setCurrentFileAction(file))


    }

    function handleBack(){
        navigate("/my_room_admin");
    }

    function handleDelete(){
        dispatch(showDeleteModalAction())
    }

    async function hadnleConfirm(){

        let selected = anno.getSelected()
        let anno_meta = localStorage.getItem("anno_meta")


        let annotations = anno.getAnnotations().map(an => {
            let markup ={}
            markup._id = an.id
            markup.type = an.target.selector.type
            markup.value = an.target.selector.value

            if (an.body.filter(prop => prop.purpose === 'category').length > 0){
                markup.category = {id : an.body.filter(prop => prop.purpose === 'category')[0].value}
            }
            if (an.body.filter(prop => prop.purpose === 'meta').length > 0){
                markup.meta = an.body.filter(prop => prop.purpose === 'meta')[0].value
                if (selected && anno_meta){
                    if (selected.id === an.id){
                        markup.meta = anno_meta
                    }
                }
            }

            return markup
        })

        if (anno){
            let anno_list = JSON.parse(localStorage.getItem("anno_list"))
            let new_anno = anno.getAnnotations().length
            if (anno_list){
                if(anno_list.length !== new_anno){
                    setReload(!reload)
                }
            }else{
                if (new_anno > 0){
                    setReload(!reload)
                }
            }
        }

        anno.clearAnnotations();


        await dispatch(addMarkupAction(currentDataset._id,currentFolder.info.name,currentFile,annotations))
        await dispatch(setCurrentFileAnnotationsAction(""))
        let new_files = Array.from(files)
        let index = new_files.findIndex(el => el.name === currentFile);

        if(index !== files.length-1){
            await dispatch(setCurrentFileAction(files[index+1].name))
        }else {
            await dispatch(getFileMurkupsAction(currentFile))
        }
        await dispatch(getFilesAction(currentDataset._id,currentFolder.info.name))
    }

    async function clickListener(e){
        if (anno){
            if (!e.target.classList.contains("meta_control")) {
                anno.saveSelected();
                setMeta("")
                setEditMeta(false)
            }
        }
    }

    async function handleMetaChange(e) {
        setMeta(e.target.value)
        localStorage.setItem("anno_meta",e.target.value)
    }

    function handleActiveMetaEdit() {
        if(anno){
            if (anno.getSelected()){
                setEditMeta(true)
            }
        }
    }

    return ( currentFolder &&
        <div className="anno_content" onClick={(e)=>clickListener(e)}>
            <div className="adm_mr">
                <div className="adm_mr_btn_group">
                    <button type="button" onClick={handleBack} className="adm_mr_btn">
                        <img src={IconBack} alt="Logo"/>
                        <p className="gt_h4 gt_gb">{currentDataset.info.name}</p>
                        <p className="gt_h4 "> / {currentFolder.info.name}</p>
                    </button>
                </div>

                <div className="search">
                    <label htmlFor="search">
                        <img src={IconSearch} alt="search"/>
                    </label>
                    <input placeholder="Поиск по имени файла" id="search" className="gt_pt" value={search} onChange={(e)=>setSearch(e.target.value)} />
                </div>

                <div className="anno_table">
                    <div className="files_block">
                        <div className="files_block_header">
                            <p className="gt_pt"><span className="gt_th">Обработано: </span>{files.filter(file=>file.annotated).length} / {files.length}</p>
                        </div>
                        <div className="files_block_list">
                            {files.filter(file => file.name.toLowerCase().includes(search.toLowerCase())).map(file => (
                                <button type="button" className={`gt_flex_row files_block_item ${file.name === currentFile ? "active" : ""}`} key={file.name} onClick={()=>handleActiveFile(file.name)}>
                                    <img src={file.annotated ? IconFileMark : IconFile} alt="file"/><p className="gt_pt files_block_item_text">{file.name}</p>
                                    <div className="mod_circles">
                                    {file.modifiers.slice(-1).map((mod) =>
                                            <div className="name_circle" key={file.name+mod}>
                                                <p className="gt_ps">{mod}</p>
                                            </div>
                                    )}
                                    </div>

                                </button>
                            ))}
                        </div>
                    </div>
                    <div className="annotation_main">
                        <div className="annotation_block">

                            {fileUrl.length > 0 ? <img
                                    ref={imgEl}
                                    src={URL.createObjectURL(fileUrl[0])}
                                    alt="markup"
                                    className="anno_image"
                                    id="main"/> :
                                <img
                                    ref={imgEl}
                                    src={IconWait}
                                    alt="markup"
                                id="empty"/>}



                            <div className={`search meta_control ${!editMeta ? "meta_disabled" : ""}`} >
                                <label htmlFor="meta-info">
                                    <button type="button" className="meta_control">
                                        <img src={editMeta ? IconEdit : IconEditDisable} alt="meta-info" className="meta_control" onClick={handleActiveMetaEdit}/>
                                    </button>
                                </label>
                                <input id="meta-info" className="gt_pt meta_control" value={meta} onChange={(e)=>handleMetaChange(e)} disabled={!editMeta}/>
                            </div>

                            <div className="class_btn_block" >
                                {currentDataset.info.categories.map((cat,i) =>
                                    <button type="button" onClick={()=>selectCategory(cat.id)} key={cat.id} className={`cat_button ${cat.id === activeCategory ? "active" : ""}`}>
                                        <div className="class_element" >
                                            <div className="main_color " style={{backgroundColor: cat.color}}/>
                                            <div className="gt_pt op_color" style={{backgroundColor: COLORS.filter(color => color.color === cat.color)[0].opacity}}>
                                                <p>{cat.name} <span className="gt_th">{i+1}</span></p>
                                            </div>
                                        </div>
                                    </button>
                                )}
                            </div>

                            <div className="confirm_btn">
                                <button type="button" className="gt_btn_primary" onClick={hadnleConfirm}>Подтвердить</button>
                                <button type="button" className="gt_btn_primary del_btn" onClick={handleDelete}>Удалить</button>

                            </div>
                        </div>
                        <div className="annotation_btns">
                              <button type="button" className={`drawing_tool ${tool === "rect" ? "active" : ""}`} onClick={()=>toggleTool("rect")}>
                                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5455 3.125H1.45455C1.25371 3.125 1.09091 3.29289 1.09091 3.5V12.5C1.09091 12.7071 1.25371 12.875 1.45455 12.875H14.5455C14.7463 12.875 14.9091 12.7071 14.9091 12.5V3.5C14.9091 3.29289 14.7463 3.125 14.5455 3.125ZM1.45455 2C0.651222 2 0 2.67157 0 3.5V12.5C0 13.3284 0.651222 14 1.45455 14H14.5455C15.3488 14 16 13.3284 16 12.5V3.5C16 2.67157 15.3488 2 14.5455 2H1.45455Z" fill="#0D4CD3"/>
                                  </svg>
                              </button>
                            <button type="button" className={`drawing_tool ${tool === "polygon" ? "active" : ""}`} onClick={()=>toggleTool("polygon")}>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6974 5.81798L14.7544 12.4067L11.1496 10.9087C10.8036 10.765 10.4246 10.7301 10.0596 10.8086L1.39126 12.6718L3.43717 7.62374C3.67506 7.03679 3.62557 6.36663 3.30429 5.82425L1.84137 3.35456L13.6974 5.81798ZM14.1779 4.71069C14.4873 4.77499 14.7263 5.03137 14.7781 5.35476L15.9892 12.9037C16.0866 13.5106 15.5077 13.9946 14.9579 13.7661L10.7265 12.0078C10.5881 11.9502 10.4364 11.9363 10.2905 11.9677L0.914867 13.9829C0.315869 14.1117 -0.176449 13.4896 0.0609188 12.9039L2.38709 7.16441C2.48225 6.92963 2.46245 6.66156 2.33394 6.44461L0.41415 3.20362C0.0664225 2.61659 0.559423 1.88107 1.21002 2.01625L14.1779 4.71069Z" fill="#0D4CD3"/>
                                </svg>
                            </button>
                            <button type="button" className={`drawing_tool ${tool === "delete" ? "active" : ""}`} onClick={()=>toggleTool("delete")}>
                                <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.74795 2.07275C3.94144 0.880273 5.02361 0 6.2961 0H8.7039C9.97639 0 11.0586 0.880274 11.252 2.07275L11.3484 2.66664H12.652H14.0625L14.0625 2.66667H15V4.00001H13.9531L13.1705 13.5401C13.0566 14.9285 11.8352 16 10.3665 16H4.63349C3.16478 16 1.94335 14.9285 1.82946 13.5401L1.04688 4.00001H0V2.66667H0.937503L0.9375 2.66664H2.348H3.65159L3.74795 2.07275ZM9.92562 2.66664H5.07438L5.13785 2.2755C5.2258 1.73346 5.71769 1.33334 6.2961 1.33334H8.7039C9.28231 1.33334 9.7742 1.73346 9.86215 2.2755L9.92562 2.66664ZM2.45738 4.00001L3.23148 13.4367C3.28842 14.1309 3.89914 14.6667 4.63349 14.6667H10.3665C11.1009 14.6667 11.7116 14.1309 11.7685 13.4367L12.5426 4.00001L2.45738 4.00001Z" fill="#0D4CD3"/>
                                </svg>
                            </button>
                        </div>
                    </div>

                </div>

            </div>
            {showDeleteModal && <DeleteModal />}
        </div>
    );
}

export default Annotation;
