import {Link} from "react-router-dom";
import IconPlus from "../../../../../assets/icons/icon_plus.svg";
import "../AdminDatasetsComponent/AdminDatasetsComponent.scss"
import {useSelector} from "react-redux";

function EmptyRoomAdministrator () {
    const {userInfo} = useSelector(store=>store.user)
    return (
        <div className="adm_mr" data-testid="empty-datasets">
            <div className="adm_mr_title">
                <p className="gt_h3">Моя комната</p>
                {/*
                <div className="adm_mr_select">
                    <div className="gt_pt adm_mr_draft">Черновики</div>
                    <div className="gt_pt adm_mr_publish">Опубликованы</div>
                </div>
                */}
            </div>
            <p className="gt_pt gt_th adm_mr_text">
                {
                    userInfo.role === "admin" ? "Список наборов данных пустой. Пожалуйста, создайте новый набор данных." :
                        <>
                            <pre className="gt_pt">Список доступных наборов данных пустой. </pre>
                            <pre className="gt_pt">Для вашей организации нет присвоенных ролей в наборах данных. </pre>
                            <pre className="gt_pt">Свяжитесь с администратором для решения спорных ситуаций.</pre>
                        </>
                }

            </p>
            {userInfo.role === "admin" ?
                <div className="adm_mr_btn_group">
                    <Link to="/create_dataset" className="adm_mr_btn"><img src={IconPlus} alt="Logo"/><p className="gt_pt gt_gb">Создать набор данных</p></Link>
                    {/*<button className="adm_mr_btn"><img src={IconPlus} alt="Logo"/><p className="gt_pt gt_gb">Использовать шаблон</p></button>*/}
                </div>
                : ""}

        </div>
    )
}

export default EmptyRoomAdministrator;
