import "./InputGosteh.scss";
import {useState} from "react";


function InputGosteh ({placeholder="",value="",inputChange,index="", validate=""}){

    const[val,setVal] = useState(value)
    const [valid,setValid] = useState(false)

    function handleChange(e){
        if (validate === "ogrn"){
            let numberRegex = /^\d+$/;
            if((numberRegex.test(e.target.value) && e.target.value.length <= 15) || e.target.value.length === 0){
                setVal(e.target.value)
                //inputChange(e.target.value,index)
            }
        }else {
            setVal(e.target.value)
            inputChange(e.target.value,index)
        }
    }

    function handleCheckInput(e){
        if (validate === "ogrn"){
            if (e.target.value.length < 13 || e.target.value.length === 14){
                setValid(true)
            }else {
                setValid(false)
            }
            inputChange(e.target.value,index)
        }
    }

    return (
        <>
            <input onBlur={(e) => handleCheckInput(e)} placeholder={placeholder} className="gt_input gt_pt shadow" value={val} onChange={(e)=>handleChange(e)}/>
            {valid && <p className="gt_ps gt_gr">Неверный формат: 13 или 15 символов</p>}
        </>
    )
}

export default InputGosteh;
