import "./AdminFolderComponent.scss"
import "../AdminDatasetsComponent/AdminDatasetsComponent.scss"
import IconBack from "../../../../../assets/icons/icon-arrow-left.svg";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import IconSearch from "../../../../../assets/icons/icon_search.svg";
import EmptyFolder from "../../../../../assets/icons/pic_not_found.svg";
import FolderInfoComponent from "./FolderInfoComponent/FolderInfoComponent";
import {disableFolderModeAction} from "../../../../../store/reducers/datasetReducer";
import UploadModal from "../../../../UploadModal/UploadModal";
import {getActiveEventsAction, getEventsAction, getFoldersAction} from "../../../../../store/actions/FolderAction";
import DatasetList from "../AdminDatasetsComponent/DatasetList/DatasetList";
import PaginationComponent from "../../../../PaginationComponent/PaginationComponent";
import {hideUploaderAction, setFileUrlAction, setUploadEvent} from "../../../../../store/reducers/folderReducer";
import {getCurrentDatasetAction, getDatasetAction} from "../../../../../store/actions/datasetAction";

function AdminFolderComponent(){
    const {currentDataset} = useSelector(store=>store.dataset)

    const {showUploadModal,uploadEvent, folders, currentFolder ,activateInterval} = useSelector(store=>store.folder)
    const [search,setSearch] = useState("")

    const [currentItems,setCurrentItems] = useState(folders)
    const [activeFolder,setActiveFolder] = useState("")
    function handleSearch(e){
        setSearch(e.target.value)
        setCurrentItems(folders.filter(folder=> folder.info.name.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0))
    }

    useEffect(()=>{
        setCurrentItems(folders)
    },[folders])

    useEffect(()=>{
        if (currentFolder){
            setActiveFolder(currentFolder.info.name)
        }
    },[currentFolder])

    useEffect(()=>{
        if (currentDataset){
            dispatch(getCurrentDatasetAction(currentDataset._id))
        }
    },[])

    useEffect(()=>{
        dispatch(getActiveEventsAction(currentDataset._id))
    },[currentDataset])

    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(getFoldersAction(currentDataset._id,currentFolder))
        if(!activateInterval) return;

        const interval = setInterval(() => {
            if (!uploadEvent) return;
            dispatch(getEventsAction(uploadEvent))
            dispatch(getFoldersAction(currentDataset._id,currentFolder))
        }, 2000);

        return () => clearInterval(interval);
    },[activateInterval,uploadEvent])




    function handleBack(){
        dispatch(setUploadEvent(""))
        dispatch(hideUploaderAction())
        dispatch(disableFolderModeAction())
        dispatch(setFileUrlAction([]))
        localStorage.removeItem("current_dataset")
        localStorage.removeItem("folder_mode")
        dispatch(getCurrentDatasetAction(currentDataset._id))
        dispatch(getDatasetAction(currentDataset._id))

    }

    return (
        <div className="adm_mr">
            <div className="adm_mr_btn_group">
                <button type="button" onClick={handleBack} className="adm_mr_btn">
                    <img src={IconBack} alt="Logo"/>
                    <p className="gt_h4 gt_gb">Набор данных {currentDataset.info.name}</p>
                </button>
            </div>

            <div className="search">
                <label htmlFor="search">
                    <img src={IconSearch} alt="search"/>
                </label>
                <input placeholder="Поиск по папкам" id="search" className="gt_pt" value={search} onChange={(e)=>handleSearch(e)}/>
            </div>

            <div className="adm_mr_table">
                <div className="adm_mr_table_left">
                    <div className="adm_mr_table_left_colums folder-colums">
                        <p className="gt_pt gt_th">Имя папки</p>
                        <p className="gt_pt gt_th">Обработка</p>
                        <p className="gt_pt gt_th">Обработано</p>
                    </div>
                    {currentItems.length > 0 ?
                        <DatasetList datasets={currentItems} activeDataset={activeFolder} setActiveDataset={setActiveFolder} type="folder"/>
                        :
                        <div className="empty_folders">
                            <img src={EmptyFolder} alt="empty folder"/>
                            <div className="empty_folders_text">
                                <p className="gt_h4">Нет созданных папок.</p>
                                <p className="gt_pt">Загрузите архивы с файлами для автоматического создания папки.</p>
                            </div>
                        </div>
                    }
                    <PaginationComponent list={folders} setCurrentItems={setCurrentItems} />
                </div>

                <FolderInfoComponent />
            </div>
            {showUploadModal && <UploadModal />}
        </div>
    )
}

export default AdminFolderComponent;
