
export const COLORS = [{color:'#FF0000', opacity : 'rgb(255, 0, 0, 0.3)'},{color: '#FF6B00', opacity : 'rgb(255, 107, 0, 0.3)'},
                {color:'#FFA800' , opacity : 'rgb(255, 168, 0, 0.3)'},{color: '#FFE500', opacity :'rgb(255, 229, 0, 0.3)'},
                {color: '#1E6E39', opacity : 'rgb(30, 110, 57, 0.3)'},{color: '#00FF0A', opacity : 'rgb(0, 255, 10, 0.3)'},
                {color: '#00CFB6', opacity : 'rgb(0, 207, 182, 0.3)'},{color: '#00FFF0', opacity : 'rgb(0, 255, 240, 0.3)'},
                {color: '#238885', opacity : 'rgb(35, 136, 133, 0.3)'},{color: '#00A3FF', opacity : 'rgb(0, 163, 255, 0.3)'},
                {color: '#0029FF', opacity : 'rgb(0, 41, 255, 0.3)'},{color:'#7000FF', opacity : 'rgb(112, 0, 255, 0.3)'},
                {color: '#8D00CF', opacity : 'rgb(141, 0, 207, 0.3)'},{color: '#FF00F5', opacity : 'rgb(255, 0, 245, 0.3)'},
                {color: '#8B3A00', opacity : 'rgb(139, 58, 0, 0.3)'},{color: '#8E0026', opacity : 'rgb(142, 0, 38, 0.3)'}]


export const UNKNOWN_CLASS = {color:'#666262', opacity : 'rgb(102, 98, 98, 0.3)'}
