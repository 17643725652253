import "./RequirementsTableComponent.scss"
import LogErrorIcon from "../../../../../../../assets/icons/icon_log_error.svg";
import LogSuccessIcon from "../../../../../../../assets/icons/icon_log_success.svg";
import CheckIcon from "../../../../../../../assets/icons/icon_check.svg";
import CheckIconDisabled from "../../../../../../../assets/icons/icon_check_disable.svg";
import ArrowUpIcon from "../../../../../../../assets/icons/icon_arrow_up.svg";
import ArrowTopIcon from "../../../../../../../assets/icons/icon_arrow_top.svg";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import DatasetChecks from "./DatasetChecks";
import IconPlus from "../../../../../../../assets/icons/icon_plus.svg";
import IconDelete from "../../../../../../../assets/icons/icon_delete.svg";
import {showChecksModalAction} from "../../../../../../../store/reducers/appReducer";
import {
    getCurrentDatasetAction,
    getValidationsAction,
    updateValidationAction
} from "../../../../../../../store/actions/datasetAction";
import {
    getActiveValidateEventsAction,
    getEventsAction,
    startValidationAction
} from "../../../../../../../store/actions/FolderAction";


function RequirementsTableComponent() {

    const dispatch = useDispatch()

    const [detailProcessing,setDetailProcessing] = useState(false)
    const [showChecks,setShowChecks] = useState(false)
    const {currentDataset,validations,validationProgress,validationProgressShow,activateValidateInterval,validationEvent} = useSelector(store=>store.dataset)

    const [validChecks,setValidChecks] = useState([])

    let count_files  = 0
    let count_annotations  = 0

    if (currentDataset){
        if (currentDataset.files_per_cat){
            if (currentDataset.files_per_cat.length > 0){
                currentDataset.files_per_cat.forEach(file => {
                    count_files = count_files + file.count
                })
            }


            if (currentDataset.annotations_per_cat.length > 0){
                currentDataset.annotations_per_cat.forEach(file => {
                    count_annotations = count_annotations + file.count
                })
            }
        }
    }



    useEffect(()=>{
        dispatch(getValidationsAction(currentDataset._id))
    },[currentDataset])

    useEffect(()=>{
        if(!activateValidateInterval) return;

        const interval = setInterval(() => {
            if (!validationEvent) return;
            dispatch(getEventsAction(validationEvent,currentDataset.room_id))
            dispatch(getCurrentDatasetAction(currentDataset._id))
        }, 2000);

        return () => clearInterval(interval);
    },[activateValidateInterval,validationEvent])

    useEffect(()=>{
        if(currentDataset.room_id){
            dispatch(getActiveValidateEventsAction(currentDataset._id,currentDataset.room_id))
        }
    },[currentDataset])

    function handleShowChecks(){
        setDetailProcessing(false)
        setShowChecks(true)
    }

    function handleShowProcessing(){
        setDetailProcessing(true)
        setShowChecks(false)
    }

    async function handleDeleteCheks(){
        let checked = validChecks.filter(el=>el.value)

        if (checked.length > 0){
            checked = checked.map(el => el.name)
            let ds_valid = currentDataset.validations.map(val => val._id)
            let opt_val = validations.filter(el => el.optional).map(el => el._id)
            let old_opt_val = opt_val.filter(el => ds_valid.includes(el))

            let new_opt = old_opt_val.filter(el => !checked.includes(el))
            await dispatch(updateValidationAction(currentDataset._id,new_opt))
            await dispatch(getCurrentDatasetAction(currentDataset._id))
            let new_checked = validChecks
            new_checked = new_checked.map(check => {
                if(old_opt_val.includes(check.name)){
                    check.value = false
                }
               return check
            })
            setValidChecks(new_checked)
        }
    }

    function handleStartValidation(){
        if (validChecks.length > 0){
            let validation_list = validChecks.filter(val => val.value).map(val=>val.name)
            dispatch(startValidationAction(currentDataset._id,currentDataset.room_id,validation_list))
        }

    }

    return ( currentDataset &&
            <div>
                <div className="third_row">
                    <div className="processed processed_count">
                        <p className="gt_pt gt_th">Обработано:</p>
                        <div className="stats">
                            <img src={(currentDataset.annotated === currentDataset.uploaded) && (currentDataset.uploaded !==0) ? LogSuccessIcon : LogErrorIcon} alt="error"/>
                            <p className="gt_pt">{currentDataset.annotated} / {currentDataset.uploaded}</p>
                        </div>
                    </div>
                </div>
                <div className="third_row fourth_row">
                    <div className="processed">
                        <p className="gt_pt gt_th">Проверки:</p>
                        <div className="stats">
                            <img src={currentDataset.validations.filter(val => val.done).length === currentDataset.validations.length ? LogSuccessIcon : LogErrorIcon} alt="error"/>
                            <p className="gt_pt">{currentDataset.validations.filter(val => val.done).length} / {currentDataset.hasOwnProperty("validations") ? currentDataset.validations.length : "?"}</p>
                        </div>
                    </div>
                    <div className="check_gr">
                        <button type="button" className="back gt_btn_secondary validate_vtn" onClick={handleStartValidation} disabled={!currentDataset.room_id || !(currentDataset.uploaded > 0)}>
                            <img src={!currentDataset.room_id || !(currentDataset.uploaded > 0) ? CheckIconDisabled : CheckIcon} alt="check"/>
                            <p className="gt_ps ">Запустить проверку</p>
                        </button>
                        {!showChecks &&
                            <button type="button" className="back" onClick={handleShowChecks}>
                                <img src={ArrowUpIcon} alt="check"/>
                                <p className="gt_ps gt_gb">Подробнее</p>
                            </button>
                        }

                    </div>
                </div>
                {showChecks &&
                    <div className="checks_show_block">
                        {validationProgressShow &&
                            <div className="validate_progress">
                                <div className="progress_block__progress-bar">
                                    <div
                                        className="progress_block__upload-bar"
                                        style={{ width: validationProgress + '%' }}
                                    />
                                </div>
                            </div>
                        }

                        <DatasetChecks setOpt={setValidChecks}/>
                        {currentDataset.roles.includes("admin") &&
                            <div className="cheks_contol_btn">
                                <button type="button" className="add_cheks" onClick={() => dispatch(showChecksModalAction())}>
                                    <img src={IconPlus} alt="icon plus"/>
                                    <p className="gt_pt gt_gb">Добавить проверку</p>
                                </button>
                                <button type="button" className="add_cheks" onClick={handleDeleteCheks}>
                                    <img src={IconDelete} alt="icon delete"/>
                                    <p className="gt_pt gt_gr">Удалить проверку</p>
                                </button>
                            </div>

                        }
                        <div className="checks_hide">
                            <button type="button" className="hide_btn" onClick={() => setShowChecks(false)}>
                                <img src={ArrowTopIcon} alt="check"/>
                                <p className="gt_ps gt_gb">Скрыть</p>
                            </button>
                        </div>
                    </div>}

                <div className="third_row last_row">
                    <div className="processed">
                        <p className="gt_pt gt_th">Плановое количество файлов:</p>
                        <div className="stats">
                            <img src={currentDataset.uploaded >= currentDataset.info.counts ? LogSuccessIcon : LogErrorIcon} alt="error"/>
                            <p className="gt_pt">{currentDataset.uploaded} / {currentDataset.info.counts}</p>
                        </div>
                    </div>
                    {!detailProcessing &&
                        <button type="button" className="back" onClick={handleShowProcessing}>
                            <img src={ArrowUpIcon} alt="check"/>
                            <p className="gt_ps gt_gb">Подробнее</p>
                        </button>
                    }

                </div>
                {detailProcessing &&
                    <div className="detail_processing">
                        <div className="cat_block">
                            <div className="class_detail class_detail_header">
                                <p className="gt_pt gt_th ">Имя класса</p>
                                <p className="gt_pt gt_th center">Файлов</p>
                                <p className="gt_pt gt_th center">Разметок</p>
                            </div>
                        </div>

                        <div className="cat_block">
                            <div>
                                {
                                    currentDataset.info.categories.filter(cat => !cat.is_default).map(cat => (
                                        <div className="class_detail" key={cat.name + cat.color}>
                                            <p className="gt_pt">{cat.name}</p>
                                            <div className="class_detail_files">
                                                <p className="gt_pt item">{currentDataset.files_per_cat.filter(file => file.id === cat.id).length > 0 ? currentDataset.files_per_cat.filter(file => file.id === cat.id)[0].count : "0"}</p>
                                                <p className="gt_pt gt_th item">/</p>
                                                <p className="gt_pt ">{count_files > 0  && currentDataset.files_per_cat.filter(file => file.id === cat.id).length > 0 ? Math.round(Number(currentDataset.files_per_cat.filter(file => file.id === cat.id)[0].count) / count_files * 100)+"%" : "0%" }</p></div>
                                            <div className="class_detail_files">
                                                <p className="gt_pt item">{currentDataset.annotations_per_cat.filter(file => file.id === cat.id).length > 0 ? currentDataset.annotations_per_cat.filter(file => file.id === cat.id)[0].count : "0"}</p>
                                                <p className="gt_pt gt_th item">/</p>
                                                <p className="gt_pt">{count_annotations > 0  && currentDataset.annotations_per_cat.filter(file => file.id === cat.id).length > 0 ? Math.round(Number(currentDataset.annotations_per_cat.filter(file => file.id === cat.id)[0].count) / count_annotations * 100)+"%" : "0%" }</p>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>

                            <button type="button" className="hide_btn" onClick={() => setDetailProcessing(false)}>
                                <img src={ArrowTopIcon} alt="check"/>
                                <p className="gt_ps gt_gb">Скрыть</p>
                            </button>
                        </div>
                    </div>
                }
            </div>
    )
}

export default RequirementsTableComponent;
