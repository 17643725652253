const SHOW_UPLOAD_MODAL = "SHOW_UPLOAD_MODAL"
const HIDE_UPLOAD_MODAL = "HIDE_UPLOAD_MODAL"
const SHOW_UPLOADER = "SHOW_UPLOADER"
const HIDE_UPLOADER = "HIDE_UPLOADER"
const CHANGE_UPLOAD_PROGRESS = "CHANGE_UPLOAD_PROGRESS"
const CHANGE_PROCESSING_PROGRESS = "CHANGE_PROCESSING_PROGRESS"
const SET_UPLOAD_EVENT = "SET_UPLOAD_EVENT"
const SET_FOLDERS = "SET_FOLDERS"
const SET_CURRENT_FOLDER = "SET_CURRENT_FOLDER"
const ACTIVATE_INTERVAL = "ACTIVATE_INTERVAL"
const DISABLE_INTERVAL = "DISABLE_INTERVAL"
const SET_FILES = "SET_FILES"
const ADD_FILE_URL = "ADD_FILE_URL"
const SET_FILE_URL = "SET_FILE_URL"
const SET_CURRENT_FILE = "SET_CURRENT_FILE"
const SET_CURRENT_FILE_ANNOTATIONS = "SET_CURRENT_FILE_ANNOTATIONS"


const initialState = {
    showUploadModal:false,
    isVisible:false,
    uploadProgress:0,
    processingProgress:0,
    uploadEvent:"",
    folders:[],
    currentFolder:"",
    activateInterval:false,
    files:[],
    fileUrl: [],
    currentFile: "",
    currentFileAnnotations: "",
}

export const folderReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_UPLOAD_MODAL:
            return { ...state, showUploadModal: true }
        case HIDE_UPLOAD_MODAL:
            return { ...state, showUploadModal: false}
        case SHOW_UPLOADER:
            return { ...state, isVisible: true }
        case HIDE_UPLOADER:
            return { ...state, isVisible: false }
        case CHANGE_UPLOAD_PROGRESS:
            return { ...state, uploadProgress: action.payload }
        case CHANGE_PROCESSING_PROGRESS:
            return { ...state, processingProgress: action.payload }
        case SET_UPLOAD_EVENT:
            return { ...state, uploadEvent: action.payload }
        case SET_FOLDERS:
            return { ...state, folders: action.payload }
        case SET_CURRENT_FOLDER:
            return { ...state, currentFolder: action.payload }
        case ACTIVATE_INTERVAL:
            return { ...state, activateInterval: true }
        case DISABLE_INTERVAL:
            return { ...state, activateInterval: false }
        case SET_FILES:
            return { ...state, files: action.payload }
        case ADD_FILE_URL:
            return { ...state, fileUrl: [...state.fileUrl,action.payload] }
        case SET_FILE_URL:
            return { ...state, fileUrl: action.payload }
        case SET_CURRENT_FILE:
            return { ...state, currentFile: action.payload }
        case SET_CURRENT_FILE_ANNOTATIONS:
            return { ...state, currentFileAnnotations: action.payload }
        default:
            return state
    }
}
export const showUploadModalAction = () => ({ type: SHOW_UPLOAD_MODAL })
export const hideUploadModalAction = () => ({ type: HIDE_UPLOAD_MODAL })
export const showUploaderAction = () => ({ type: SHOW_UPLOADER })
export const hideUploaderAction = () => ({ type: HIDE_UPLOADER })
export const changeUploadProgressAction = (progress) => ({ type: CHANGE_UPLOAD_PROGRESS, payload: progress })
export const changeProcessingProgressAction = (progress) => ({ type: CHANGE_PROCESSING_PROGRESS, payload: progress })
export const setUploadEvent = (event) => ({ type: SET_UPLOAD_EVENT, payload: event })
export const setFoldersAction = (folders) => ({ type: SET_FOLDERS, payload: folders })
export const setCurrentFolderAction = (folder) => ({ type: SET_CURRENT_FOLDER, payload: folder })
export const activateIntervalAction = () => ({ type: ACTIVATE_INTERVAL})
export const disableIntervalAction = () => ({ type: DISABLE_INTERVAL})
export const setFolderFilesAction = (files) => ({ type: SET_FILES, payload: files })
export const addFileUrlAction = (file) => ({ type: ADD_FILE_URL, payload: file })
export const setFileUrlAction = (file) => ({ type: SET_FILE_URL, payload: file })
export const setCurrentFileAction = (file) => ({ type: SET_CURRENT_FILE, payload: file })
export const setCurrentFileAnnotationsAction = (annotations) => ({ type: SET_CURRENT_FILE_ANNOTATIONS, payload: annotations })
