import "./AddChecksModal.scss"
import IconSearch from "../../assets/icons/icon_search.svg";
import {useEffect, useState} from "react";
import IconClose from "../../assets/icons/icon_close_black.svg";
import {useDispatch, useSelector} from "react-redux";
import {hideChecksModalAction} from "../../store/reducers/appReducer";
import CheckboxGosteh from "../CheckboxGosteh/CheckboxGosteh";
import {getCurrentDatasetAction, updateValidationAction} from "../../store/actions/datasetAction";

function AddChecksModal(){
    const {showChecksModal} = useSelector(store=>store.app)
    const {currentDataset,validations} = useSelector(store=>store.dataset)

    const dispatch = useDispatch()
    const [search,setSearch] = useState("")

    const [checks,setChecks] = useState([])
    function handleSearch(e){
        setSearch(e.target.value)
    }

    useEffect(()=>{
        let curent_valid = []
        if(currentDataset){
            curent_valid = currentDataset.validations.map(val => val._id)
        }

        if (validations.length > 0){
            let options = validations.filter(val => val.optional && !curent_valid.includes(val._id)).map(val=>({
                name:val._id,
                label:val.name,
                value:false,
            }))
            setChecks(options)
        }

    },[currentDataset,validations])


    function changeCheck(name,value){
        let new_checks = checks
        let index = new_checks.findIndex(p => p.name === name);
        new_checks[index].value = value
        setChecks(new_checks)
    }

    async function handleAddBtn(){
        let ds_valid = currentDataset.validations.map(val => val._id)
        let sel_checks = checks.filter(el => el.value).map(el=>el.name)
        let opt_val = validations.filter(el => el.optional).map(el => el._id)

        let old_opt_val = opt_val.filter(el => ds_valid.includes(el))
        let new_opt = sel_checks.filter(el => !ds_valid.includes(el.name))
        new_opt.concat(old_opt_val)

        if(new_opt.length > 0){
            await dispatch(updateValidationAction(currentDataset._id,new_opt))
            await dispatch(getCurrentDatasetAction(currentDataset._id))
        }
        dispatch(hideChecksModalAction())

    }
    return (showChecksModal &&
        <div className="check_modal">
            <div className="window">
                <div className="close_check_modal">
                    <p className="gt_h3">Добавить проверку</p>
                    <button type="button" className="checks_close_btn" onClick={()=>dispatch(hideChecksModalAction())}><img src={IconClose} alt="close"/></button>
                </div>


                <div className="search">
                    <label htmlFor="search">
                        <img src={IconSearch} alt="search"/>
                    </label>
                    <input placeholder="Поиск проверки" id="search" className="gt_pt" value={search} onChange={(e)=>handleSearch(e)}/>
                </div>

                <div className="checks_block">
                    <p className="gt_pt gt_th">Доступные проверки</p>
                    {checks.map(check => (
                        <CheckboxGosteh
                            key={check.name}
                            label={check.label}
                            check_value={check.value}
                            onChange={changeCheck}
                            name={check.name}
                        />
                    ))}
                </div>

                <button type="button" className="gt_btn_primary" onClick={handleAddBtn}>
                    <p className="gt_pt">Добавить</p>
                </button>
            </div>
        </div>
    )
}

export default AddChecksModal;
