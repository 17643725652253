import {Link} from "react-router-dom";
import "./AdminDatasetsComponent.scss"
import IconPlus from "../../../../../assets/icons/icon_plus.svg"
import DatasetList from "./DatasetList/DatasetList";
import IconSearch from "../../../../../assets/icons/icon_search.svg";
import IconArrowLeft from "../../../../../assets/icons/arrow_left.svg";
import {useEffect, useState} from "react";
import RightTableComponent from "./RightTable/RightTableComponent";
import {useSelector} from "react-redux";
import PaginationComponent from "../../../../PaginationComponent/PaginationComponent";


function AdminDatasetsComponent ({datasets}) {

    const {currentDataset} = useSelector(store=>store.dataset)
    const {userInfo} = useSelector(store=>store.user)

    const [datasetsPagination,setDatasetsPagination] = useState(datasets)

    const [search,setSearch] = useState("")

    const [showDetail,setShowDetail] = useState(true)

    const [activeDataset,setActiveDataset] = useState("")

    const [currentItems,setCurrentItems] = useState(datasets)

    useEffect(()=>{
        setDatasetsPagination(datasets.filter(ds=> ds.info.name.toLowerCase().indexOf(search.toLowerCase()) >= 0))
    },[datasets])

    useEffect(()=>{
        setActiveDataset(currentDataset._id)
    },[currentDataset])


    function handleSearch(e){
        setSearch(e.target.value)
        //setCurrentItems(datasets.filter(ds=> ds.info.name.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0))
        setDatasetsPagination(datasets.filter(ds=> ds.info.name.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0))
    }

    return (
        <div className="adm_mr" data-testid="dataset-component">
            <div className="adm_mr_title">
                <p className="gt_h3">Моя комната</p>
                {/*
                <div className="adm_mr_select">
                    <div className="gt_pt adm_mr_draft">Черновики</div>
                    <div className="gt_pt adm_mr_publish">Опубликованы</div>
                </div>
                */}
            </div>

            <div className="search">
                <label htmlFor="search">
                    <img src={IconSearch} alt="search"/>
                </label>
                <input placeholder="Поиск по наборам данных" id="search" className="gt_pt" value={search} onChange={(e)=>handleSearch(e)}/>
            </div>

            {userInfo.role === "admin" ?
            <div className="adm_mr_btn_group">
                <Link to="/create_dataset" className="adm_mr_btn"><img src={IconPlus} alt="Logo"/><p className="gt_pt gt_gb">Создать набор данных</p></Link>
                {/*<button className="adm_mr_btn" ><img src={IconPlus} alt="Logo"/><p className="gt_pt gt_gb">Использовать шаблон</p></button> */}
            </div> : ""}



            <div className="adm_mr_table">
                <div className="adm_mr_table_left">
                    <div className="adm_mr_table_left_colums">
                        <p className="gt_pt gt_th">Наименование набора данных</p>
                        <p className="gt_pt gt_th">Обработка</p>
                        <p className="gt_pt gt_th">Статус</p>
                        {!showDetail && <button type="button" className="hide_btn" onClick={()=>setShowDetail(true)}><img src={IconArrowLeft} alt="left"/></button>}

                    </div>
                    <DatasetList datasets={currentItems} activeDataset={activeDataset} setActiveDataset={setActiveDataset} type={"dataset"}/>
                    <PaginationComponent list={datasetsPagination} setCurrentItems={setCurrentItems} />
                </div>
                {showDetail && <RightTableComponent dataset={datasets.filter(dataset => dataset._id === activeDataset)[0]} setShowDetail={setShowDetail}/>}

            </div>
        </div>
    )
}

export default AdminDatasetsComponent;
