import "./CheckboxGosteh.scss"
import {useState} from "react";

const CheckboxGosteh = ({ label,check_value,onChange,name }) => {

    const [value,setValue] = useState(check_value)

    function handleChange(){
        setValue(!value)
        onChange(name,!value)
    }
    return (
        <div key={name}>
            <input type="checkbox" className="custom-checkbox" checked={value} onChange={handleChange} id={name} name={name}/>
            <label htmlFor={name} className="gt_pt ">
                {label}
            </label>
        </div>

    );
};

export default CheckboxGosteh;
