import { combineReducers } from "redux";
import { appReducer } from "./appReducer";
import {datasetReducer} from "./datasetReducer";
import {folderReducer} from "./folderReducer";
import {userReducer} from "./userReducer";

export const rootReducer = combineReducers({
    app: appReducer,
    dataset:datasetReducer,
    folder:folderReducer,
    user:userReducer,
})
