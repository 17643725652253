import "./FileUploadProgress.scss"
import {useDispatch, useSelector} from "react-redux";
import IconClose from "../../assets/icons/icon_close_black.svg";
import IconInfo from "../../assets/icons/icon_log_info.svg";
import IconSuccess from "../../assets/icons/icon_log_success.svg";
import {hideUploaderAction} from "../../store/reducers/folderReducer";

function FileUploadProgress(){

    const dispatch = useDispatch()
    const {isVisible,uploadProgress,processingProgress} = useSelector(store=>store.folder)


    return ( isVisible &&
        <div className="upload_progress">
            <button type="button" className="upload_progress_close_btn" onClick={()=>dispatch(hideUploaderAction())}><img src={IconClose} alt="close"/></button>

            <div className="title_block">
                <img src={uploadProgress !== 100 ? IconInfo : IconSuccess} alt="status"/>
                <p className="gt_h4">Загрузки файлов</p>
            </div>

            <div className="progress_block">
                    <div className="progress_block__progress-bar">
                        <div
                            className="progress_block__upload-bar"
                            style={{ width: uploadProgress + '%' }}
                        />
                    </div>
                    <p className="gt_pt">{uploadProgress}%</p>
            </div>

            <div className="title_block">
                <img src={processingProgress !== 100 ? IconInfo : IconSuccess} alt="status"/>
                <p className="gt_h4">Обработка файлов</p>
            </div>

            <div className="progress_block">
                <div className="progress_block__progress-bar">
                    <div
                        className="progress_block__upload-bar"
                        style={{ width: processingProgress + '%' }}
                    />
                </div>
                <p className="gt_pt">{processingProgress}%</p>
            </div>
        </div>
    )
}

export default FileUploadProgress;
