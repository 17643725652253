import "./SuccessLoginPage.scss"
import GerbIcon from "../../assets/icons/gerb.svg"
import HouseIcon from "../../assets/icons/house.svg"
import ArrowIcon from "../../assets/icons/arrow_right.svg"
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getUserDataAction, selectOrganizationAction} from "../../store/actions/userAction";
import {useNavigate} from "react-router-dom";

function SuccessLoginPage(){

    const navigate = useNavigate();

    const dispatch = useDispatch()
    const {userInfo} = useSelector(store=>store.user)

    useEffect(()=>{
        const queryParams = new URLSearchParams(window.location.search);
        const code = queryParams.get('code');
        if (code){
            dispatch(getUserDataAction(code))
        }

    },[])

    async function handleSelect(oid){
        await dispatch(selectOrganizationAction(oid))
        navigate("/my_room_admin");
    }
    return (userInfo &&
        <div className="sl_content" data-testid="success-login-page">
            <div className="sl_header">
                <img src={GerbIcon} alt="gerb"/>
                <p className="gt_h4">Государственная платформа искусственного интеллекта</p>
            </div>
            <p className="gt_h1">Войти как</p>
            <div className="sl_org_list">
                {userInfo.orgs.map(org => (
                    <button type="button" className="sl_org_item" key={org.name} onClick={()=>handleSelect(org.oid)} data-testid="test-organization-list">
                        <div className="sl_org_item_name">
                            <img src={HouseIcon} alt="house"/>
                            <p className="gt_pt">{org.name}</p>
                        </div>
                        <img className="arrow" src={ArrowIcon} alt="arrow"/>
                    </button>
                ))}

            </div>
        </div>
    )
}

export default SuccessLoginPage;
