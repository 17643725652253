import "./DeleteModal.scss"
import {useDispatch, useSelector} from "react-redux";
import {hideDeleteModalAction} from "../../store/reducers/appReducer";
import {deleteDatasetAction} from "../../store/actions/datasetAction";
import {deleteFileAction} from "../../store/actions/FolderAction";
import {setCurrentFileAction} from "../../store/reducers/folderReducer";
import {useNavigate} from "react-router-dom";

function DeleteModal (){

    const dispatch = useDispatch()
    const navigate = useNavigate();

    const {currentDataset,folderMode} = useSelector(store=>store.dataset)
    const {currentFolder,currentFile,files} = useSelector(store=>store.folder)

    function handleCansel(){
        dispatch(hideDeleteModalAction())
    }

    function handleDelete(){
        if (folderMode){
            let new_files = Array.from(files)
            let index = new_files.findIndex(el => el.name === currentFile);
            dispatch(deleteFileAction(currentDataset._id,currentFolder.info.name,currentFile))
            if (new_files.length - 1 === 0 ){
                navigate("/my_room_admin");
            }else {

                if (new_files.length-index > 1){
                    new_files.splice(0,index+1)
                }else {
                    new_files.splice(0,index-1)
                }
                dispatch(setCurrentFileAction(new_files[0].name))
            }


        }else {
            dispatch(deleteDatasetAction(currentDataset._id))
        }
        dispatch(hideDeleteModalAction())
    }

    return (
        <div className="modal">
            <div className="modal_window">
                <p className="gt_h3">Вы точно хотите удалить {folderMode ? "файл" : "набор данных"}?</p>
                <div className="modal_btn_group">
                    <button type="button" className="gt_btn_primary modal_del_btn" onClick={handleDelete}>Да, удалить</button>
                    <button type="button" className="gt_btn_primary" onClick={handleCansel}>Нет, отмена</button>
                </div>

            </div>
        </div>
    )
}

export default DeleteModal;
