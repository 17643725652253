import "./RightTableComponent.scss"
import PublishIcon from "../../../../../../assets/icons/icon_publish.svg";
import PublishIconActive from "../../../../../../assets/icons/icon_publish_active.svg";
import EditIcon from "../../../../../../assets/icons/icon_edit.svg";
import DeleteIcon from "../../../../../../assets/icons/icon_delete.svg";
import ArrowIcon from "../../../../../../assets/icons/arrow_right.svg";
import {useEffect, useState} from "react";
import RequirementsTableComponent from "./RequirementsTableComponent/RequirementsTableComponent";
import DescriptionTableComponent from "./DescriptionTableComponent/DescriptionTableComponent";
import {useDispatch, useSelector} from "react-redux";
import {showDeleteModalAction} from "../../../../../../store/reducers/appReducer";
import {Link} from "react-router-dom";
import DataseLogs from "../../../../../DataseLogs/DataseLogs";
import {publishDatasetAction} from "../../../../../../store/actions/datasetAction";
import {getEventsAction} from "../../../../../../store/actions/FolderAction";


function RightTableComponent({setShowDetail}){
    const {currentDataset,publishProgressShow,publishProgress,activatePublishInterval,publishEvent} = useSelector(store=>store.dataset)

    const [activeZone,setActiveZone] = useState("requirements")

    const dispatch = useDispatch()

    let publish_activate = currentDataset.validations.filter(val => val.done).length === currentDataset.validations.length &&
                           (currentDataset.annotated === currentDataset.uploaded) && (currentDataset.uploaded !==0) &&
                            currentDataset.uploaded >= currentDataset.info.counts
    function handleDelete(){
        dispatch(showDeleteModalAction())
    }

    function handlePublish(){
        dispatch(publishDatasetAction(currentDataset._id))
    }

    useEffect(()=>{
        if(!activatePublishInterval) return;

        const interval = setInterval(() => {
            if (!publishEvent) return;
            dispatch(getEventsAction(publishEvent,currentDataset.room_id))
        }, 2000);

        return () => clearInterval(interval);
    },[activatePublishInterval,publishEvent])

    return(
            <div className="right_col">
                <div className="top_row">
                    <div className="crud_btn">
                        {currentDataset.roles.includes("admin") &&
                            <>
                                <button type="button" className="gt_btn_primary btn" disabled={!publish_activate || activatePublishInterval} onClick={handlePublish}>
                                    <img src={publish_activate || activatePublishInterval ? PublishIconActive : PublishIcon} alt="publish"/>
                                    <p className="gt_pt">Опубликовать</p>
                                </button>
                                <Link to="/create_dataset?action=edit" type="button" className="btn" >
                                    <img src={EditIcon} alt="edit"/>
                                </Link>
                                <button type="button" className="btn" onClick={handleDelete}>
                                    <img src={DeleteIcon} alt="edit"/>
                                </button>
                            </>}
                    </div>
                    <button type="button" className="btn" onClick={()=>setShowDetail(false)}>
                        <img src={ArrowIcon} alt="hide"/>
                    </button>
                </div>


                {
                    publishProgressShow &&
                    <div className="publish_progress">
                        <div className="validate_progress">
                            <div className="progress_block__progress-bar">
                                <div
                                    className="progress_block__upload-bar"
                                    style={{ width: publishProgress + '%' }}
                                />
                            </div>
                        </div>
                    </div>
                }





                <div className="second_row">
                    <button type="button" className={`gt_pt part_item ${activeZone === "requirements" ? "part_item_active" : ""} `} onClick={()=>setActiveZone("requirements")}>Требования к публикации</button>
                    <button type="button" className={`gt_pt part_item ${activeZone === "description" ? "part_item_active" : ""} `} onClick={()=>setActiveZone("description")}>Описание</button>
                    <button type="button" className={`gt_pt part_item ${activeZone === "logs" ? "part_item_active" : ""} `} onClick={()=>setActiveZone("logs")}>Логи</button>
                </div>
                {
                    activeZone === "requirements" ? <RequirementsTableComponent /> :
                    activeZone === "description" ? <DescriptionTableComponent  /> :
                    activeZone === "logs" ? <DataseLogs  /> : ""
                }
            </div>

    )
}

export default RightTableComponent;
